<template>
    <v-form
        ref="form"
        aria-autocomplete="off"
        autocomplete="off"
        @submit.prevent="submit"
    >
        <v-card>
            <v-card-text>
                <v-text-field
                    v-model="form.name"
                    :label="$t('Name')"
                    :error-messages="nameErrors"
                    required
                    :disabled="loading"
                    @input="$v.form.name.$touch()"
                    @blur="$v.form.name.$touch()"
                ></v-text-field>
                <v-text-field
                    v-model="form.email"
                    :label="$t('Email')"
                    type="email"
                    :error-messages="emailErrors"
                    :disabled="loading"
                    @input="$v.form.email.$touch()"
                    @blur="$v.form.email.$touch()"
                ></v-text-field>
            </v-card-text>
        </v-card>

        <h3 class="text-h5 mb-3 mt-5">{{ $t("Password") }}</h3>

        <v-card>
            <v-card-text>
                <v-text-field
                    v-model="form.password"
                    :label="$t('Password')"
                    :append-icon="
                        passwordHidden ? 'visibility_off' : 'visibility'
                    "
                    :type="passwordHidden ? 'password' : 'text'"
                    :error-messages="passwordErrors"
                    :disabled="loading"
                    :hint="$t('At least 6 characters')"
                    @click:append="() => (passwordHidden = !passwordHidden)"
                ></v-text-field>
                <v-text-field
                    v-model="form.password_confirmation"
                    :label="$t('Confirm New Password')"
                    :error-messages="confirmPasswordErrors"
                    :type="passwordHidden ? 'password' : 'text'"
                    :disabled="loading"
                    aria
                ></v-text-field>
            </v-card-text>
        </v-card>

        <v-layout mt-5>
            <v-spacer></v-spacer>
            <v-btn
                text
                :disabled="loading"
                :to="{ name: 'profile' }"
                color="grey darken-2"
                >{{ $t("Cancel") }}</v-btn
            >
            <v-btn
                type="submit"
                :loading="loading"
                :disabled="loading"
                color="primary"
                >{{ $t("Save") }}</v-btn
            >
        </v-layout>
    </v-form>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";
import Form from "~/mixins/form";
import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";

export default {
    mixins: [Form, validationMixin],

    validations: {
        form: {
            name: { required },
            email: { required, email },
            password: { required, minLength: minLength(6) },
            password_confirmation: {
                required,
                sameAsPassword: sameAs("password")
            }
        }
    },

    data: () => ({
        passwordHidden: true,

        form: {
            name: null,
            email: null,
            password: null,
            password_confirmation: null
        }
    }),

    computed: {
        ...mapGetters({
            auth: "auth/user"
        }),
        nameErrors() {
            const errors = [];
            if (!this.$v.form.name.$dirty) return errors;
            !this.$v.form.name.required && errors.push("Name erforderlich.");
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.form.password.$dirty) return errors;
            !this.$v.form.password.minLength &&
                errors.push("Passwort muss mindestens 6 Zeichen lang sein.");
            !this.$v.form.password.required &&
                errors.push("Passwort erforderlich.");
            return errors;
        },
        confirmPasswordErrors() {
            const errors = [];
            if (!this.$v.form.password_confirmation.$dirty) return errors;
            !this.$v.form.password_confirmation.sameAsPassword &&
                errors.push("Passwörter stimmen nicht überein");
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.form.email.$dirty) return errors;
            !this.$v.form.email.email &&
                errors.push("E-Mail Addresse ungültig.");
            !this.$v.form.email.required &&
                errors.push("E-mail Addresse ist erforderlich.");
            return errors;
        }
    },

    mounted() {
        this.form = Object.assign(this.form, this.auth);
    },

    methods: {
        submit() {
            this.$v.$touch();
            if (!this.$v.form.$invalid) {
                this.loading = true;
                axios
                    .put(api.path("profile"), this.form)
                    .then(res => {
                        this.$store.dispatch("ui/showSnackbar", {
                            text: this.$t("Profil erfolgreich gespeichert."),
                            color: "success"
                        });

                        this.$emit("success", res.data.data);
                    })
                    .catch(err => {
                        this.handleErrors(err.response.data.errors);
                    })
                    .then(() => {
                        this.loading = false;
                    });
            }
        }
    }
};
</script>
