/**
 * auth.js
 */
export const SET_USER = "SET_USER";
export const SET_TENANT = "SET_TENANT";
export const LOGOUT = "LOGOUT";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const SET_TOKEN = "SET_TOKEN";

/**
 * ui.js
 */
export const TOGGLE_NAVIGATION_DRAWER_MINI = "TOGGLE_NAVIGATION_DRAWER_MINI";
export const SET_NAVIGATION_DRAWER_FULL = "SET_NAVIGATION_DRAWER_FULL";
export const UI_ADD_ERROR = "UI_ADD_ERROR";
export const UI_POP_ERROR = "UI_POP_ERROR";
export const UI_SET_LOADING = "UI_SET_LOADING";
export const UI_SET_PRODUCT_FILTER = "UI_SET_PRODUCT_FILTER";
export const UI_SHOW_SNACKBAR = "UI_SHOW_SNACKBAR";
export const UI_CLOSE_SNACKBAR = "UI_CLOSE_SNACKBAR";

/**
 * products.js
 */
export const SET_PRODUCT_LIST = "SET_PRODUCT_LIST";
export const SET_PRODUCT_EXPORT_LIST = "SET_PRODUCT_EXPORT_LIST";
export const SET_PRODUCTS_ALL = "SET_PRODUCTS_ALL";
export const SET_PRODUCT_LIST_TOTAL_COUNT = "SET_PRODUCT_LIST_TOTAL_COUNT";
export const SET_PRODUCT_TO_EDIT = "SET_PRODUCT_TO_EDIT";
export const UPDATE_PRODUCT_FIELDS = "UPDATE_PRODUCT_FIELDS";
export const RESET_EDITED_PRODUCT = "RESET_EDITED_PRODUCT";
export const SET_PRODUCT_SEARCH_RESULT = "SET_PRODUCT_SEARCH_RESULT";
export const PRODUCT_LIST_SET_UPDATE_EXPORT_WEB =
    "PRODUCT_LIST_SET_UPDATE_EXPORT_WEB";
export const PRODUCT_LIST_SET_EXPORT_WEB = "PRODUCT_LIST_SET_EXPORT_WEB";
export const COPY_PRODUCT_TO_EDIT = "COPY_PRODUCT_TO_EDIT";
export const SET_PRODUCT_LISTPRODUCT_SEARCH_RESULT =
    "SET_PRODUCT_LISTPRODUCT_SEARCH_RESULT";

/**
 * mediavalet.js
 */
export const SET_MEDIAVALET_TOKEN = "SET_MEDIAVALET_TOKEN";

/**
 * master-data.js
 */
export const MASTER_DATA_SET_ITEMS = "MASTER_DATA_SET_ITEMS";
export const MASTER_DATA_SET_TENANTS = "MASTER_DATA_SET_TENANTS";
export const MASTER_DATA_SET_ITEMS_COUNT = "MASTER_DATA_SET_ITEMS_COUNT";
export const MASTER_DATA_RESET_ITEMS = "MASTER_DATA_RESET_ITEMS";
export const MASTER_DATA_SET_ATTRIBUTES = "MASTER_DATA_SET_ATTRIBUTES";
export const MASTER_DATA_ADD_ITEM = "MASTER_DATA_ADD_ITEM";
export const MASTER_DATA_DELETE_ITEM = "MASTER_DATA_DELETE_ITEM";
export const MASTER_DATA_SET_DATA = "MASTER_DATA_SET_DATA";
