import "@mdi/font/css/materialdesignicons.css";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import "babel-polyfill";
import Vue from "vue";
import FlagIcon from "vue-flag-icon";
import Vuetify from "vuetify";
import VuetifyConfirm from "vuetify-confirm";
import vuexI18n from "vuex-i18n";
import { theme } from "./config";
import translationsDe from "./lang/de";
import "./plugins/index";
import router from "./router/index";
import store from "./store/index";
Vue.use(FlagIcon);

// Set up vuetify theme
const opts = {
    theme: theme,
    icons: {
        iconfont: "mdi",
        icons: {
            flag_de: "flag-icon flag-icon-de",
            flag_en: "flag-icon flag-icon-gb",
            flag_fr: "flag-icon flag-icon-fr"
        }
    },
    options: {
        customProperties: true
    }
};
Vue.use(Vuetify);
const vuetify = new Vuetify(opts);

// Setup i18n with store
Vue.use(vuexI18n.plugin, store, {
    moduleName: "i18n"
    /*onTranslationNotFound(locale, key) {
        console.warn(`i18n :: Key '${key}' not found for locale '${locale}'`);
    }*/
});
Vue.i18n.add("de", translationsDe);
// Default is german
Vue.i18n.set("de");

// Setup Vuetify confirm dialogs
Vue.use(VuetifyConfirm, {
    vuetify,
    buttonTrueText: Vue.i18n.translate("OK"),
    buttonFalseText: Vue.i18n.translate("Cancel")
});

// Handle all unhandled promise rejections
/*window.addEventListener('unhandledrejection', function (p) {
    p.preventDefault()
})*/

Sentry.init({
    Vue,
    dsn:
        "https://fb514569ea2f4e59a768b34a6df8968e@o257043.ingest.sentry.io/6024108",
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["localhost:8080", "middleware.hauert.ch", /^\//]
        })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5
});

//window.Vue = Vue;
export const app = new Vue({
    vuetify,
    router,
    store,
    render: h => h(require("$comp/App").default)
}).$mount("#app");
