<template>
    <div class="fill-height">
        <app-nav @navToggle="navToggle"></app-nav>
        <top-menu @navToggle="navToggle"></top-menu>

        <v-main>
            <v-container fluid py-0>
                <transition name="fade" mode="out-in">
                    <router-view></router-view>
                </transition>
            </v-container>
        </v-main>
        <snackbar />
        <app-footer></app-footer>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    components: {
        "app-nav": require("./shared/AppNav").default,
        "top-menu": require("./shared/TopMenu").default,
        "app-footer": require("./shared/AppFooter").default,
        snackbar: require("./shared/Snackbar").default
    },

    data: () => ({
        mini: true
    }),

    computed: {
        ...mapGetters({
            errors: "ui/errors",
            mvToken: "mediavalet/token"
        })
    },

    watch: {
        errors(newErrors) {
            if (newErrors[0] != undefined) {
                this.$store.dispatch("ui/showSnackbar", {
                    text: newErrors[0],
                    color: "error"
                });
            }
        }
    },

    mounted() {
        if (!this.mvToken) {
            this.$store.dispatch("mediavalet/getMVToken");
        }
    },

    methods: {
        navToggle() {
            this.$store.dispatch("ui/toggleNavigationDrawerMini");
        }
    }
};
</script>
