<template>
    <v-card class="elevation-0">
        <v-card-title>
            <h3 class="text-h5 font-weight-thin primary--text pl-4 pt-2">
                {{ $t("Texts") }}
            </h3>
        </v-card-title>
        <v-card-text>
            <v-container py-0 px-2 grid-list-xs fluid>
                <v-layout wrap>
                    <v-flex xs12>
                        <h4 class="text-h5 font-weight-thin primary--text">
                            {{ $t("Descriptions") }}
                        </h4>
                    </v-flex>
                    <v-flex xs12>
                        <translateable
                            :value="product.description_short"
                            :default-language="language"
                            :multiline="true"
                            :label="$t('Description short')"
                            :maxlength="128"
                            :readonly="readonly"
                            :counter="128"
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event,
                                    'description_short'
                                )
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        light
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>{{
                                    $t(
                                        "Produktnamen und Anwendung (wie vorne auf Packung)"
                                    )
                                }}</span>
                            </v-tooltip>
                        </translateable>
                    </v-flex>
                    <v-flex xs12>
                        <translateable
                            :value="product.features_text"
                            :default-language="language"
                            :multiline="true"
                            :readonly="readonly"
                            :label="$t('Features')"
                            @input="
                                $emit('updateProduct', $event, 'features_text')
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        light
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>{{
                                    $t(
                                        "max. 5 Vorteile eines Produktes (Aufzählung)"
                                    )
                                }}</span>
                            </v-tooltip>
                        </translateable>
                    </v-flex>
                    <v-flex xs12>
                        <translateable
                            :value="product.description_long"
                            :default-language="language"
                            :multiline="true"
                            :readonly="readonly"
                            :label="$t('Description Text')"
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event,
                                    'description_long'
                                )
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        light
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>{{
                                    $t(
                                        "Einführungs-Produkttext (erster Abschnitt von Verpackungstext)"
                                    )
                                }}</span>
                            </v-tooltip>
                        </translateable>
                    </v-flex>
                    <v-flex xs12>
                        <h4 class="text-h5 font-weight-thin primary--text">
                            {{ $t("Features and dosage") }}
                        </h4>
                    </v-flex>
                    <v-flex xs12>
                        <translateable
                            :value="product.application_text"
                            :default-language="language"
                            :multiline="true"
                            :readonly="readonly"
                            :label="$t('Application')"
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event,
                                    'application_text'
                                )
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        light
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>{{ $t("Anwendungsempfehlung") }}</span>
                            </v-tooltip>
                        </translateable>
                    </v-flex>
                    <v-flex xs12>
                        <translateable
                            :value="product.dosage_text"
                            :default-language="language"
                            :multiline="true"
                            :label="$t('Dosage')"
                            :readonly="readonly"
                            @input="
                                $emit('updateProduct', $event, 'dosage_text')
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        light
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>{{
                                    $t(
                                        "Dosierungsempfehlung / Düngerplan detailliert"
                                    )
                                }}</span>
                            </v-tooltip>
                        </translateable>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            :value="product.dosage_exact"
                            type="number"
                            step="0.01"
                            class="mx-5"
                            :disabled="readonly"
                            :label="$t('Dosage simple')"
                            @input="
                                $emit('updateProduct', $event, 'dosage_exact')
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        dark
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>Exakte Dosierung</span>
                            </v-tooltip>
                            <template slot="append">{{
                                $t(product.dosage_unit)
                            }}</template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-select
                            :value="product.dosage_unit"
                            :items="dosageUnitItems"
                            :label="$t('Dosage unit')"
                            class="mx-5"
                            :disabled="readonly"
                            clearable
                            @input="
                                $emit('updateProduct', $event, 'dosage_unit')
                            "
                        >
                            <template slot="selection" slot-scope="data">{{
                                $t(data.item)
                            }}</template>
                            <template slot="item" slot-scope="data">{{
                                $t(data.item)
                            }}</template>
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        dark
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span
                                    >Einheit der Dosierung für
                                    Dosierungsangaben</span
                                >
                            </v-tooltip>
                        </v-select>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            :value="product.dosage_min"
                            type="number"
                            step="0.01"
                            :disabled="readonly"
                            class="mx-5"
                            :label="$t('Dosage min')"
                            @input="
                                $emit('updateProduct', $event, 'dosage_min')
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        dark
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>Dosierung minimum</span>
                            </v-tooltip>
                            <template slot="append">{{
                                $t(product.dosage_unit)
                            }}</template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <v-text-field
                            :value="product.dosage_max"
                            type="number"
                            step="0.01"
                            class="mx-5"
                            :label="$t('Dosage max')"
                            :disabled="readonly"
                            @input="
                                $emit('updateProduct', $event, 'dosage_max')
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        dark
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>Dosierung maximum</span>
                            </v-tooltip>
                            <template slot="append">{{
                                $t(product.dosage_unit)
                            }}</template>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12>
                        <h4 class="text-h5 font-weight-thin primary--text">
                            {{ $t("Hints") }}
                        </h4>
                    </v-flex>
                    <v-flex xs12>
                        <translateable
                            :value="product.take_note"
                            :default-language="language"
                            :multiline="true"
                            :readonly="readonly"
                            :label="$t('Zu beachten')"
                            @input="$emit('updateProduct', $event, 'take_note')"
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        light
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>{{
                                    $t(
                                        "Produktespezifische Informationen nebst der Gebrauchsanleitung"
                                    )
                                }}</span>
                            </v-tooltip>
                        </translateable>
                    </v-flex>
                    <v-flex xs12>
                        <v-select
                            :value="product.storage_notes"
                            :items="attributes.storage_notes"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            class="mx-5"
                            :label="$t('Storage notes')"
                            small-chips
                            :disabled="readonly"
                            return-object
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event.id,
                                    'storage_note_id'
                                )
                            "
                        ></v-select>
                    </v-flex>
                    <v-flex xs12>
                        <v-select
                            :value="product.disposal_notes"
                            :items="attributes.disposal_notes"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            return-object
                            class="mx-5"
                            :disabled="readonly"
                            :label="$t('Disposal notes')"
                            small-chips
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event.id,
                                    'disposal_note_id'
                                )
                            "
                        ></v-select>
                    </v-flex>
                    <v-flex xs12>
                        <v-select
                            :value="product.emergency_information"
                            :items="attributes.emergency_information"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            class="mx-5"
                            :disabled="readonly"
                            return-object
                            :label="$t('Emergency information')"
                            small-chips
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event.id,
                                    'emergency_information_id'
                                )
                            "
                        ></v-select>
                    </v-flex>
                    <v-flex xs12>
                        <h4 class="text-h5 font-weight-thin primary--text">
                            {{ $t("Website") }}
                        </h4>
                    </v-flex>
                    <v-flex xs12>
                        <translateable
                            :value="product.special_text"
                            :default-language="language"
                            :multiline="true"
                            :readonly="readonly"
                            :label="$t('Spezialtext')"
                            @input="
                                $emit('updateProduct', $event, 'special_text')
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        light
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>{{ $t("Spezialtext") }}</span>
                            </v-tooltip>
                        </translateable>
                    </v-flex>
                    <v-flex xs12>
                        <translateable
                            :value="product.marketing_text"
                            :default-language="language"
                            :multiline="true"
                            :label="$t('Marketingtext')"
                            :maxlength="1700"
                            :readonly="readonly"
                            :counter="1700"
                            @input="
                                $emit('updateProduct', $event, 'marketing_text')
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        light
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>{{
                                    $t("Produktetext, max. 1700 Zeichen")
                                }}</span>
                            </v-tooltip>
                        </translateable>
                    </v-flex>
                    <v-flex xs12>
                        <translateable
                            :value="product.label_1_text"
                            :default-language="language"
                            :multiline="false"
                            :label="$t('Labeltext 1')"
                            :readonly="readonly"
                            :maxlength="35"
                            :counter="35"
                            @input="
                                $emit('updateProduct', $event, 'label_1_text')
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        light
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>{{
                                    $t(
                                        "Anschreibung des Warenregals mit Produktenamen"
                                    )
                                }}</span>
                            </v-tooltip>
                        </translateable>
                    </v-flex>
                    <v-flex xs12>
                        <translateable
                            :value="product.label_2_text"
                            :default-language="language"
                            :multiline="false"
                            :label="$t('Labeltext 2')"
                            :maxlength="35"
                            :readonly="readonly"
                            :counter="35"
                            @input="
                                $emit('updateProduct', $event, 'label_2_text')
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        light
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>{{
                                    $t(
                                        "Anschreibung des Warenregals mit wichtigster Anwendung"
                                    )
                                }}</span>
                            </v-tooltip>
                        </translateable>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import translateable from "$comp/shared/TranslateableText";
import { mapGetters } from "vuex";

export default {
    components: {
        translateable
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        fallbackLanguage: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            dosageUnitItems: [
                "pieces_per_plant",
                "g/m²",
                "%",
                "‰",
                "ml/kg",
                "ml/l",
                "g/l",
                "g/m³",
                "kg/m³",
                "kg/ha",
                "l/ha",
                "l/m²"
            ]
        };
    },
    computed: {
        ...mapGetters({
            attributes: "master-data/attributes"
        })
    }
};
</script>
