<template>
    <versions
        :product="product"
        :live="live"
        :history="history"
        :draft="draft"
        @change="fetchVersions"
    />
</template>

<script>
// import moment from "moment";
import axios from "axios";
import { api } from "~/config";
import { mapGetters, mapActions } from "vuex";
// import { defaultLanguageIso } from "~/config";

export default {
    components: {
        versions: require("$comp/product/versions/Versions").default
    },

    data() {
        return {
            draft: {},
            history: [],
            live: {},
            product: {
                name: ""
            }
        };
    },

    computed: {
        ...mapGetters({
            loading: "ui/loading",
            tenant: "auth/tenant"
        }),

        crumbs() {
            let label = "";
            if (Object.keys(this.product).length !== 0) {
                label = this.$t("Item") + " " + this.product.item_number;
            }

            return [
                {
                    text: this.$t("Dashboard"),
                    disabled: false,
                    href: "/"
                },
                {
                    text: this.$t("Products"),
                    disabled: false,
                    href: "/products"
                },
                {
                    text: label,
                    disabled: true,
                    href: "/products/" + this.$route.params.id
                }
            ];
        }
    },

    created() {
        this.fetchVersions();
    },

    methods: {
        ...mapActions({
            setLoading: "ui/setLoading"
        }),

        async fetchVersions() {
            const productId = this.$route.params.id;
            this.setLoading(true);
            await axios
                .get(api.path("products.versions", { id: productId }))
                .then(r => r.data)
                .then(({ history, draft, product }) => {
                    this.history = [...history];
                    this.draft = draft;
                    this.live = history[0];
                    this.product = product;
                });
            this.setLoading(false);
        },

        async cancelEdit() {
            const answer = await this.saveChangesDialog();
            if (answer) {
                this.resetEdited(this.product.id);
                this.$router.go(-1);
            }
        }
    }
};
</script>
