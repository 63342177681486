<template>
    <v-app-bar class="elevation-1" clipped-left app>
        <v-app-bar-nav-icon
            v-if="navigationDrawerMini"
            class="ml-0"
            large
            @click.stop="$emit('navToggle')"
        ></v-app-bar-nav-icon>
        <v-icon v-else @click.stop="$emit('navToggle')"
            >mdi-chevron-left</v-icon
        >
        <v-spacer />
        <v-toolbar-title>
            <router-link v-once class="pr-2" :to="{ name: 'index' }">
                <v-img
                    :height="32"
                    :min-width="200"
                    class="mt-5"
                    contain
                    :src="require('~/assets/images/logo_hauert_gruen.svg')"
                />
            </router-link>
        </v-toolbar-title>
        <v-progress-linear
            v-show="loading"
            id="global-loading"
            absolute
            bottom
            :indeterminate="loading"
            height="3"
            class="my-0"
            color="accent"
        ></v-progress-linear>
        <v-spacer />

        <div class="text-center">
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
            >
                <template #activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon>person</v-icon>
                    </v-btn>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-avatar color="primary" size="40" class="mr-2">
                                <span class="white--text text-h5">{{
                                    user.name[0]
                                }}</span>
                            </v-avatar>

                            <v-list-item-content>
                                <v-list-item-title>{{
                                    user.name
                                }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                    user.email
                                }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list>
                        <v-list-item @click="logout">
                            <v-list-item-action>
                                <v-icon color="primary"
                                    >power_settings_new</v-icon
                                >
                            </v-list-item-action>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item>
                    </v-list>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="menu = false">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </div>

        <v-dialog v-model="dialog" max-width="500px">
            <template #activator="{ on, attrs }">
                <v-btn icon color="pink" class="mr-2">
                    <v-avatar
                        tile
                        size="36"
                        class="accent white--text"
                        right
                        v-bind="attrs"
                        v-on="on"
                        >{{ tenant }}</v-avatar
                    >
                </v-btn>
            </template>

            <v-card>
                <v-card-title>
                    <h3
                        class="text-h5 font-weight-thin primary--text pl-4 pt-2"
                    >
                        {{ $t("Switch tenant") }}:
                    </h3>
                </v-card-title>
                <v-card-text>
                    <v-select
                        v-model="select"
                        :items="tenants"
                        menu-props="auto"
                        :item-text="`name.${'de'}`"
                        label="Select"
                        item-value="abbreviation"
                        hide-details
                        single-line
                        class="tenant-select"
                    >
                        <template #prepend>
                            <v-avatar
                                class="accent white--text"
                                right
                                size="32"
                                v-text="select.toUpperCase()"
                            ></v-avatar>
                        </template>
                    </v-select>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="dialog = false">
                        {{ $t("Close") }}
                    </v-btn>
                    <v-btn color="primary" @click="changeTenant">
                        {{ $t("Switch") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { settings } from "~/config";

export default {
    data: () => ({
        select: null,
        siteName: settings.siteName,
        fav: true,
        menu: false,
        message: false,
        hints: true,
        dialog: false
    }),

    computed: {
        ...mapGetters({
            loading: "ui/loading",
            navigationDrawerMini: "ui/navigationDrawerMini",
            tenants: "auth/tenants",
            tenant: "auth/tenant",
            user: "auth/user"
        })
    },

    mounted() {
        this.select = this.$store.state.auth.tenant;
    },

    methods: {
        ...mapActions({
            setTenant: "auth/setTenant"
        }),
        navToggle() {
            this.$store.dispatch("ui/toggleNavigationDrawerMini");
        },
        changeTenant() {
            this.setTenant(this.select);
            this.$router.push({ name: "index" });
            this.dialog = false;
            this.$store.dispatch("ui/showSnackbar", {
                text: this.$t("Tenant switched"),
                color: "success",
                top: true,
                right: true,
                timeout: 3000
            });
            location.reload(); 
        },
        async logout() {
            await this.$store.dispatch("auth/logout");

            this.$store.dispatch("ui/showSnackbar", {
                text: this.$t("You are logged out."),
                color: "info"
            });
            this.$router.push({ name: "login" });
        }
    }
};
</script>

<style scoped>
.tenant-select {
}
</style>
