var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"justify-end":"","mx-5":"","mb-2":""}},[_c('v-flex',{attrs:{"sm11":"","xs12":""}},[_c('v-card',{attrs:{"elevation":0}},[_c('v-card-title',[_c('h6',{staticClass:"text-h6 font-weight-thin primary--text"},[_vm._v(" "+_vm._s(_vm.$t("Datasheet export"))+" ")])]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-checkbox',{staticClass:"product-checkbox mr-2",attrs:{"value":_vm.useProducts},on:{"click":_vm.toggleUseProducts}}),_c('v-autocomplete',{attrs:{"items":_vm.products,"attach":"","chips":"","filter":_vm.filterProducts,"search-input":_vm.search,"disabled":!_vm.useProducts,"label":_vm.$t('Products'),"loading":_vm.searchLoading,"multiple":"","readonly":!_vm.useProducts,"item-value":"id","cache-items":"","item-text":"item_number","placeholder":_vm.$t(
                                    'Mit der Eingabe beginnen um zu suchen ...'
                                )},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t( "Start typing to search products." ))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
                                var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name[_vm.selectedLanguage])}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                                            ((_vm.$t('Product no')) + ": " + (item.item_number))
                                        )}})],1)]}}]),model:{value:(_vm.filter.products),callback:function ($$v) {_vm.$set(_vm.filter, "products", $$v)},expression:"filter.products"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{staticClass:"ml-10",attrs:{"items":_vm.languages,"attach":"","chips":"","multiple":"","label":_vm.$t('Languages'),"item-value":"iso","item-text":"label","error-messages":_vm.errorLang
                                    ? _vm.$t('Select at least one language')
                                    : null,"error":_vm.errorLang},model:{value:(_vm.filter.languages),callback:function ($$v) {_vm.$set(_vm.filter, "languages", $$v)},expression:"filter.languages"}})],1),(!_vm.useProducts)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.scopes,"attach":"","chips":"","multiple":"","label":_vm.$t('Kundengruppen'),"item-value":"key","item-text":"caption"},model:{value:(_vm.filter.scopes),callback:function ($$v) {_vm.$set(_vm.filter, "scopes", $$v)},expression:"filter.scopes"}})],1):_vm._e(),(!_vm.useProducts)?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.brands,"attach":"","chips":"","multiple":"","label":_vm.$t('Brands'),"item-value":"id","item-text":"name"},model:{value:(_vm.filter.brands),callback:function ($$v) {_vm.$set(_vm.filter, "brands", $$v)},expression:"filter.brands"}})],1):_vm._e()],1),_c('v-row')],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-11 mt-5 ml-2 mb-5",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.download}},[_vm._v("Download")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }