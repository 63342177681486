<template>
    <v-card class="elevation-0">
        <v-card-title>
            <h3 class="text-h5 font-weight-thin primary--text pl-4 pt-2">
                {{ $t("Attributes") }}
            </h3>
        </v-card-title>
        <v-card-text>
            <v-container py-0 px-2 grid-list-xs fluid>
                <v-layout wrap>
                    <v-flex
                        v-if="
                            attributes.chemical_types &&
                                attributes.chemical_types.length > 0
                        "
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.chemical_type"
                            :items="attributes.chemical_types"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :disabled="readonly"
                            :label="$t('Chemical type')"
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event.id,
                                    'chemical_type_id'
                                )
                            "
                        >
                            <template #selection="data">{{
                                data.item.name[language]
                            }}</template>
                        </v-combobox>
                    </v-flex>
                    <v-flex
                        v-if="
                            attributes.seasons && attributes.seasons.length > 0
                        "
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.seasons"
                            :items="attributes.seasons"
                            item-value="id"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            :label="$t('Seasons')"
                            :disabled="readonly"
                            multiple
                            @input="
                                $event => {
                                    $emit(
                                        'updateProduct',
                                        $event, // $event.map(a => a.id),
                                        'seasons'
                                    );
                                }
                            "
                        >
                        </v-combobox>
                    </v-flex>
                    <v-flex
                        v-if="
                            attributes.characteristics &&
                                attributes.characteristics.length > 0
                        "
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.characteristics"
                            :items="attributes.characteristics"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :disabled="readonly"
                            :label="$t('Characteristics')"
                            multiple
                            @input="
                                $event => {
                                    $emit(
                                        'updateProduct',
                                        $event, // $event.map(a => a.id),
                                        'characteristics'
                                    );
                                }
                            "
                        ></v-combobox>
                    </v-flex>
                    <v-flex
                        v-if="
                            attributes.effect_durations &&
                                attributes.effect_durations.length > 0
                        "
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.effect_duration"
                            :items="attributes.effect_durations"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :disabled="readonly"
                            :label="$t('Effect duration')"
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event.id,
                                    'effect_duration_id'
                                )
                            "
                        >
                            <template #selection="data">{{
                                data.item.name[language]
                            }}</template>
                        </v-combobox>
                    </v-flex>
                    <v-flex
                        v-if="
                            attributes.plant_groups &&
                                attributes.plant_groups.length > 0
                        "
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.plant_groups"
                            :items="attributes.plant_groups"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :label="$t('Plant group')"
                            :disabled="readonly"
                            multiple
                            @input="
                                $emit('updateProduct', $event, 'plant_groups')
                            "
                        ></v-combobox>
                    </v-flex>
                    <v-flex
                        v-if="attributes.plants && attributes.plants.length > 0"
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.plants"
                            :items="attributes.plants"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :label="$t('Plants')"
                            multiple
                            :disabled="readonly"
                            @input="$emit('updateProduct', $event, 'plants')"
                        ></v-combobox>
                    </v-flex>
                    <v-flex
                        v-if="attributes.tasks && attributes.tasks.length > 0"
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.tasks"
                            :items="attributes.tasks"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :label="$t('Tätigkeit / Produktseiten')"
                            multiple
                            :disabled="readonly"
                            @input="$emit('updateProduct', $event, 'tasks')"
                        ></v-combobox>
                    </v-flex>
                    <v-flex
                        v-if="
                            attributes.product_lines &&
                                attributes.product_lines.length > 0
                        "
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.product_line"
                            :items="attributes.product_lines"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :label="$t('Produktlinie')"
                            :disabled="readonly"
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event.id,
                                    'product_line_id'
                                )
                            "
                        >
                            <template #selection="data">{{
                                data.item.name[language]
                            }}</template>
                        </v-combobox>
                    </v-flex>
                    <v-flex
                        v-if="
                            attributes.application_sites &&
                                attributes.application_sites.length > 0
                        "
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.application_sites"
                            :items="attributes.application_sites"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :disabled="readonly"
                            :label="$t('Anwendungsort')"
                            multiple
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event,
                                    'application_sites'
                                )
                            "
                        ></v-combobox>
                    </v-flex>
                    <v-flex
                        v-if="
                            attributes.application_scopes &&
                                attributes.application_scopes.length > 0
                        "
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.application_scopes"
                            :items="attributes.application_scopes"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :disabled="readonly"
                            :label="$t('Application scopes')"
                            multiple
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event,
                                    'application_scopes'
                                )
                            "
                        ></v-combobox>
                    </v-flex>
                    <v-flex
                        v-if="
                            attributes.task_areas &&
                                attributes.task_areas.length > 0
                        "
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.task_areas"
                            :items="attributes.task_areas"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :disabled="readonly"
                            :label="$t('Tätigkeitsbereich')"
                            multiple
                            @input="
                                $emit('updateProduct', $event, 'task_areas')
                            "
                        ></v-combobox>
                    </v-flex>
                    <v-flex
                        v-if="
                            attributes.purposes &&
                                attributes.purposes.length > 0
                        "
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.purposes"
                            :items="attributes.purposes"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :disabled="readonly"
                            :label="$t('Purposes')"
                            multiple
                            @input="$emit('updateProduct', $event, 'purposes')"
                        ></v-combobox>
                    </v-flex>

                    <v-flex
                        v-if="
                            attributes.hazard_statements &&
                                attributes.hazard_statements.length > 0
                        "
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.hazard_statements"
                            :items="attributes.hazard_statements"
                            item-text="code"
                            item-value="id"
                            :label="$t('Hazard statements')"
                            small-chips
                            :disabled="readonly"
                            multiple
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event,
                                    'hazard_statements'
                                )
                            "
                        >
                            <template slot="item" slot-scope="data">
                                [{{ data.item.code }}]:
                                {{ data.item.statement[language] }}
                            </template>
                        </v-combobox>
                    </v-flex>
                    <v-flex
                        v-if="
                            attributes.precautionary_statements &&
                                attributes.precautionary_statements.length > 0
                        "
                        xs12
                        sm12
                        md6
                    >
                        <v-combobox
                            :value="product.precautionary_statements"
                            :items="attributes.precautionary_statements"
                            :item-text="`code`"
                            item-value="id"
                            :label="$t('Precautionary statements')"
                            small-chips
                            :disabled="readonly"
                            multiple
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event,
                                    'precautionary_statements'
                                )
                            "
                        >
                            <template slot="item" slot-scope="data">
                                [{{ data.item.code }}]:
                                {{ data.item.statement[language] }}
                            </template>
                        </v-combobox>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                        <v-text-field
                            :value="product.grain_size"
                            :label="$t('Grain size')"
                            :disabled="readonly"
                            hint="z. B.: '1.5-3 mm'"
                            @input="
                                $emit('updateProduct', $event, 'grain_size')
                            "
                        >
                            <v-tooltip slot="prepend" right>
                                <template #activator="{ on }">
                                    <v-icon
                                        color="yellow darken-1"
                                        dark
                                        v-on="on"
                                        >help_outline</v-icon
                                    >
                                </template>
                                <span>z. B.: "1.5-3 mm"</span>
                            </v-tooltip>
                        </v-text-field>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        product: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        fallbackLanguage: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            select: null
        };
    },
    computed: {
        ...mapGetters({
            attributes: "master-data/attributes"
        })
    }
};
</script>
