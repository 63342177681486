var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.renderIt)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"dense":"","disable-pagination":false,"items-per-page":-1,"hide-default-footer":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._t("title")],2),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"768px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.readonly,"outlined":"","x-small":"","color":"primary"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("add")])],1)]}}],null,false,3416913484),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Add/Edit")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"autofocus":"","items":_vm.itemTypes,"label":_vm.$t('Type'),"clearable":""},model:{value:(_vm.editedItem.type),callback:function ($$v) {_vm.$set(_vm.editedItem, "type", $$v)},expression:"editedItem.type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"single-line":"","label":_vm.$t('Value')},model:{value:(_vm.editedItem.value),callback:function ($$v) {_vm.$set(_vm.editedItem, "value", $$v)},expression:"editedItem.value"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-select',{attrs:{"items":[
                                            '%',
                                            'g/l',
                                            'ml/l',
                                            'mg/l'
                                        ],"label":_vm.$t('Unit'),"clearable":""},model:{value:(_vm.editedItem.unit),callback:function ($$v) {_vm.$set(_vm.editedItem, "unit", $$v)},expression:"editedItem.unit"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-combobox',{attrs:{"items":[
                                            'Fe',
                                            'K₂O',
                                            'Mg',
                                            'N',
                                            'NS',
                                            'NA',
                                            'P₂O₅',
                                            'PS',
                                            'S',
                                            'SiO₂' ],"label":_vm.$t('Element'),"clearable":""},model:{value:(_vm.editedItem.element),callback:function ($$v) {_vm.$set(_vm.editedItem, "element", $$v)},expression:"editedItem.element"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Text'),"clearable":""},model:{value:(_vm.editedItem.text),callback:function ($$v) {_vm.$set(_vm.editedItem, "text", $$v)},expression:"editedItem.text"}})],1)],1)],1)],1),(!_vm.readonly)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("Abbrechen")))]),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("Speichern")))])],1):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.type",fn:function(props){return [(props.item.type === 'main')?_c('span',[_vm._v(_vm._s(_vm.$t("Haupt")))]):(props.item.type === 'description')?_c('span',[_vm._v(_vm._s(_vm.$t("Beschrieb")))]):(props.item.type === 'long-term')?_c('span',[_vm._v(_vm._s(_vm.$t("Langzeit")))]):_c('span',[_vm._v(_vm._s(_vm.$t("kein")))])]}},{key:"item.text",fn:function(props){return [_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Text","single-line":"","autofocus":"","disabled":_vm.readonly},on:{"change":function($event){return _vm.$emit('input', _vm.value)}},model:{value:(props.item.text),callback:function ($$v) {_vm.$set(props.item, "text", $$v)},expression:"props.item.text"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(props.item.text)+" ")])]}},{key:"item.action",fn:function(ref){
                                        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("edit")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.readonly},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1)]}}],null,true)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }