<template>
    <v-layout justify-end mx-5 mb-2>
        <v-flex sm11 xs12>
            <v-card :elevation="0">
                <v-card-title>
                    <h6 class="text-h6 font-weight-thin primary--text">
                        {{ $t("Datasheet export") }}
                    </h6>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-checkbox
                                :value="useProducts"
                                class="product-checkbox mr-2"
                                @click="toggleUseProducts"
                            ></v-checkbox>
                            <v-autocomplete
                                v-model="filter.products"
                                :items="products"
                                attach
                                chips
                                :filter="filterProducts"
                                :search-input.sync="search"
                                :disabled="!useProducts"
                                :label="$t('Products')"
                                :loading="searchLoading"
                                multiple
                                :readonly="!useProducts"
                                item-value="id"
                                cache-items
                                :item-text="`item_number`"
                                :placeholder="
                                    $t(
                                        'Mit der Eingabe beginnen um zu suchen ...'
                                    )
                                "
                            >
                                <template #no-data>
                                    <v-list-item>
                                        <v-list-item-title>
                                            {{
                                                $t(
                                                    "Start typing to search products."
                                                )
                                            }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                                <template #item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="item.name[selectedLanguage]"
                                        ></v-list-item-title>
                                        <v-list-item-subtitle
                                            v-text="
                                                `${$t('Product no')}: ${
                                                    item.item_number
                                                }`
                                            "
                                        ></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="4">
                            <v-select
                                v-model="filter.languages"
                                :items="languages"
                                attach
                                chips
                                class="ml-10"
                                multiple
                                :label="$t('Languages')"
                                item-value="iso"
                                item-text="label"
                                :error-messages="
                                    errorLang
                                        ? $t('Select at least one language')
                                        : null
                                "
                                :error="errorLang"
                            ></v-select>
                        </v-col>
                        <v-col v-if="!useProducts" cols="12" sm="4">
                            <v-select
                                v-model="filter.scopes"
                                :items="scopes"
                                attach
                                chips
                                multiple
                                :label="$t('Kundengruppen')"
                                item-value="key"
                                item-text="caption"
                            ></v-select>
                        </v-col>
                        <v-col v-if="!useProducts" cols="12" sm="4">
                            <v-select
                                v-model="filter.brands"
                                :items="brands"
                                attach
                                chips
                                multiple
                                :label="$t('Brands')"
                                item-value="id"
                                item-text="name"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row> </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        class="ml-11 mt-5 ml-2 mb-5"
                        color="primary"
                        :loading="loading"
                        @click="download"
                        >Download</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import {
    api,
    availableLanguages,
    defaultLanguageIso,
    mandatoryLanguages
} from "~/config";

export default {
    data() {
        return {
            filter: {
                products: [],
                languages: [],
                brands: [],
                scopes: []
            },
            useProducts: false,
            brands: [],
            scopes: [
                {
                    key: "hobby",
                    caption: this.$t("Hobby")
                },
                {
                    key: "pro",
                    caption: this.$t("Profi")
                },
                {
                    key: "agric",
                    caption: this.$t("Agrar")
                }
            ],
            selectedLanguage: "de",
            errorLang: false,
            products: [],
            searchLoading: false,
            search: null,
            crumbs: [
                {
                    text: this.$t("Dashboard"),
                    disabled: false,
                    href: "/"
                },
                {
                    text: this.$t("Products"),
                    disabled: false,
                    href: "/products"
                },
                {
                    text: this.$t("Export"),
                    disabled: true,
                    href: "/products/export"
                }
            ],
            loading: false,
            pagination: {
                rowsPerPage: -1
            },
            dialog: false,
            languages: availableLanguages,
            defaultLanguageIso: defaultLanguageIso,
            mandatoryLanguages: mandatoryLanguages
        };
    },
    computed: {
        ...mapGetters({
            tenant: "auth/tenant"
        })
    },

    watch: {
        search(val) {
            if (!val || val.length < 3) return;

            this.fetchEntriesDebounced(val);
        }
    },

    created() {
        this.initialize();
        this.getTenants();
    },

    methods: {
        ...mapActions({
            fetchExportList: "products/fetchExportList",
            getTenants: "master-data/getTenants",
            setStatus: "products/setStatus",
            setProductFilter: "ui/setProductFilter"
        }),

        fetchEntriesDebounced(val) {
            // cancel pending call
            clearTimeout(this._timerId);

            // delay new call 500ms
            this._timerId = setTimeout(() => {
                this.products = [];

                this.searchLoading = true;

                axios
                    .get(
                        api.path("products.queryWithinTenant", {
                            term: val,
                            tenant: this.tenant
                        })
                    )
                    .then(r => r.data)
                    .then(products => {
                        this.products = products.data;
                    })
                    .finally(() => (this.searchLoading = false));
            }, 500);
        },

        initialize() {
            axios
                .get(api.path("masterData.list") + "/brand", {
                    params: {
                        tenant: this.tenant
                    }
                })
                .then(r => r.data)
                .then(items => {
                    this.brands = items.data;
                });
        },

        async download() {
            let appendUrl = "";
            if (this.filter.languages.length < 1) {
                this.errorLang = true;
                return false;
            }
            this.errorLang = false;
            this.loading = true;

            let payload = {
                scopes: this.filter.scopes,
                brands: this.filter.brands,
                languages: this.filter.languages,
                products: this.filter.products,
                tenant: this.tenant
            };

            if (payload.tenant !== undefined) {
                appendUrl += "&tenant=" + payload.tenant;
            }

            if (
                payload.languages !== undefined &&
                payload.languages.length > 0
            ) {
                appendUrl += "&languages=" + payload.languages.join(",");
            }

            if (payload.products !== undefined && payload.products.length > 0) {
                appendUrl += "&products=" + payload.products.join(",");
            } else {
                if (payload.scopes !== undefined && payload.scopes.length > 0) {
                    appendUrl += "&scopes=" + payload.scopes.join(",");
                }

                if (payload.brands !== undefined && payload.brands.length > 0) {
                    appendUrl += "&brands=" + payload.brands.join(",");
                }

                if (
                    payload.products !== undefined &&
                    payload.products.length > 0
                ) {
                    appendUrl += "&products=" + payload.products.join(",");
                }
            }

            axios({
                url: api.path("products.datasheetExport") + appendUrl,
                method: "GET",
                responseType: "blob"
            })
                .then(response => {
                    if (response.data.size > 0) {
                        var fileURL = window.URL.createObjectURL(
                            new Blob([response.data])
                        );

                        var fileLink = document.createElement("a");
                        fileLink.href = fileURL;
                        fileLink.setAttribute("download", "files.zip");
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    } else {
                        if (response.status === 204) {
                            this.$store.dispatch("ui/showSnackbar", {
                                text: this.$t(
                                    "No products found matching your selection"
                                ),
                                color: "warning"
                            });
                        }
                    }
                })
                .catch(() => {
                    this.$store.dispatch("ui/showSnackbar", {
                        text: this.$t("Es ist ein Fehler aufgetreten!"),
                        color: "error"
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        editItem: function(item) {
            this.$router.push({
                name: "product-edit",
                params: { id: item.id }
            });
        },

        stopEditItem: function() {
            this.dialog = false;
        },

        updateProductFilter(value) {
            this.setProductFilter(value);
        },

        filterProducts(item, queryText) {
            return (
                item.item_number
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.name[this.selectedLanguage]
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },

        toggleUseProducts() {
            this.useProducts = !this.useProducts;
            if (this.useProducts) {
                this.filter.brands = [];
                this.filter.scopes = [];
            } else {
                this.filter.products = [];
            }
        }
    }
};
</script>

<style scoped>
.action-btn {
    width: 22px;
    height: 22px;
}
.flag-inactive {
    -webkit-filter: grayscale(1) brightness(2.4);
    filter: grayscale(1) brightness(2.4);
}

.flag-check {
    margin: 0 6px;
}

.product-checkbox {
    float: left;
}
</style>
