<template>
    <v-snackbar
        v-model="snackbar.visible"
        :timeout="snackbar.timeout"
        :multi-line="snackbar.multiline === true"
        :absolute="snackbar.absolute"
        :bottom="snackbar.bottom"
        :color="snackbar.color"
        :left="snackbar.left"
        :right="snackbar.right"
        :top="snackbar.top"
        :vertical="snackbar.vertical"
    >
        <span>{{ snackbar.text }}</span>
        <v-btn text dark @click="closeSnackbar">Close</v-btn>
    </v-snackbar>
</template>

<script>
import { mapActions } from "vuex";

export default {
    computed: {
        snackbar() {
            return this.$store.state.ui.snackbar;
        }
    },

    methods: {
        ...mapActions({
            closeSnackbar: "ui/closeSnackbar"
        })
    }
};
</script>
