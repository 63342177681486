import * as types from "../mutation-types";

export const namespaced = true;

/**
 * Initial state
 */
export const state = {
    navigationDrawerMini:
        localStorage.getItem("navigationDrawerMini") === null
            ? true
            : window.localStorage.getItem("navigationDrawerMini") === "true",
    productFilter: "",
    loading: false,
    errors: [],
    snackbar: {
        visible: false,
        text: null,
        timeout: 6000,
        multiline: false,
        absolute: false,
        autoheight: true,
        bottom: false,
        left: false,
        right: true,
        top: true,
        vertical: false
    }
};

/**
 * Mutations
 */
export const mutations = {
    [types.TOGGLE_NAVIGATION_DRAWER_MINI](state) {
        state.navigationDrawerMini = !state.navigationDrawerMini;
        window.localStorage.setItem(
            "navigationDrawerMini",
            state.navigationDrawerMini
        );
    },

    [types.SET_NAVIGATION_DRAWER_FULL](state) {
        state.navigationDrawerMini = false;
        window.localStorage.setItem("navigationDrawerMini", false);
    },

    [types.UI_ADD_ERROR](state, error) {
        state.errors.unshift(error);
    },

    [types.UI_POP_ERROR](state) {
        state.errors.pop();
    },

    [types.UI_SET_LOADING](state, value) {
        state.loading = value;
    },

    [types.UI_SET_PRODUCT_FILTER](state, value) {
        state.productFilter = value;
    },

    [types.UI_SHOW_SNACKBAR](state, payload) {
        state.snackbar.text = payload.text;
        state.snackbar.multiline = payload.text.length > 50 ? true : false;

        if (payload.multiline) {
            state.snackbar.multiline = payload.multiline;
        }

        if (payload.text.length > 300) {
            state.snackbar.timeout = 0; //infinite
        }

        if (payload.timeout) {
            state.snackbar.timeout = payload.timeout;
        }

        if (payload.absolute) {
            state.snackbar.absolute = payload.absolute;
        }

        if (payload.autoheight) {
            state.snackbar.autoheight = payload.autoheight;
        }

        if (payload.bottom) {
            state.snackbar.bottom = payload.bottom;
        }

        if (payload.color) {
            state.snackbar.color = payload.color;
        }

        if (payload.left) {
            state.snackbar.left = payload.left;
        }

        if (payload.right) {
            state.snackbar.right = payload.right;
        }

        if (payload.top) {
            state.snackbar.top = payload.top;
        }

        if (payload.vertical) {
            state.snackbar.vertical = payload.vertical;
        }

        state.snackbar.visible = true;
    },

    [types.UI_CLOSE_SNACKBAR](state) {
        state.snackbar.visible = false;
        state.snackbar.multiline = false;
        state.snackbar.timeout = 6000;
        state.snackbar.text = null;
        state.snackbar.absolute = false;
        state.snackbar.autoheight = true;
        state.snackbar.bottom = false;
        state.snackbar.color = undefined;
        state.snackbar.left = false;
        state.snackbar.right = true;
        state.snackbar.top = true;
        state.snackbar.vertical = false;
    }
};

/**
 * Actions
 */
export const actions = {
    toggleNavigationDrawerMini({ commit }) {
        commit(types.TOGGLE_NAVIGATION_DRAWER_MINI);
    },

    setNavigationDrawerFull({ commit }) {
        commit(types.SET_NAVIGATION_DRAWER_FULL);
    },

    populateErrors({ commit }, error) {
        commit(types.UI_ADD_ERROR, error);
        setTimeout(() => commit(types.UI_POP_ERROR), 3000);
    },

    setLoading({ commit }, value) {
        commit(types.UI_SET_LOADING, value);
    },

    setProductFilter({ commit }, value) {
        commit(types.UI_SET_PRODUCT_FILTER, value);
    },

    showSnackbar({ commit }, value) {
        commit(types.UI_CLOSE_SNACKBAR);
        commit(types.UI_SHOW_SNACKBAR, value);
    },

    closeSnackbar({ commit }) {
        commit(types.UI_CLOSE_SNACKBAR);
    }
};

/**
 * Getters
 */
export const getters = {
    navigationDrawerMini: state => state.navigationDrawerMini,
    errors: state => state.errors,
    loading: state => state.loading,
    productFilter: state => state.productFilter,
    snackbar: state => state.snackbar
};
