<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
        <v-text-field
            v-model="form.password"
            :label="labels.password"
            :append-icon="passwordHidden ? 'visibility_off' : 'visibility'"
            :type="passwordHidden ? 'password' : 'text'"
            :error-messages="errors.password"
            :disabled="loading"
            :rules="[rules.required('password')]"
            hint="At least 6 characters"
            @click:append="() => (passwordHidden = !passwordHidden)"
        ></v-text-field>

        <v-text-field
            v-model="form.password_confirmation"
            :label="labels.password_confirmation"
            :type="passwordHidden ? 'password' : 'text'"
            :error-messages="errors.password_confirmation"
            :disabled="loading"
            :rules="[rules.required('password_confirmation')]"
        ></v-text-field>

        <v-layout class="mt-2">
            <v-spacer></v-spacer>

            <v-btn
                type="submit"
                :loading="loading"
                :disabled="loading || !valid"
                color="primary"
                >{{ $t("Set new password") }}</v-btn
            >
        </v-layout>
    </v-form>
</template>

<script>
import axios from "axios";
import { api } from "~/config";
import Form from "~/mixins/form";

export default {
    mixins: [Form],

    data: () => ({
        passwordHidden: true,

        labels: {
            password: "New Password",
            password_confirmation: "Confirm New Password"
        },

        form: {
            token: null,
            email: null,
            password: null,
            password_confirmation: null
        }
    }),

    created() {
        this.form.email = this.$route.query.email;
        this.form.token = this.$route.params.token;
    },

    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                axios
                    .post(api.path("password.reset"), this.form)
                    .then(() => {
                        this.$store.dispatch("ui/showSnackbar", {
                            text: this.$t("Your password has been reset."),
                            color: "success"
                        });
                        this.$emit("success", this.form);
                    })
                    .catch(err => {
                        this.handleErrors(err.response.data.errors);
                    })
                    .then(() => {
                        this.loading = false;
                    });
            }
        }
    }
};
</script>
