<template>
    <v-dialog :value="value">
        <v-card-title>Bild</v-card-title>
        <v-card>
            <v-img :src="src" style="max-height:70%"></v-img>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="$emit('close')"
                    >Close</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },

        src: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            lightbox: false,
            itemToDelete: {}
        };
    }
};
</script>
