var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-2 product-versions",attrs:{"fluid":"","grid-list-xl":""}},[_c('v-breadcrumbs',{staticClass:"mt-3 pl-1",attrs:{"items":_vm.crumbs}},[_c('v-icon',{attrs:{"slot":"divider"},slot:"divider"},[_vm._v("chevron_right")])],1),_c('v-card',{staticClass:"mx-auto elevation-1"},[_c('v-card-title',[_c('h3',{staticClass:"text-h5 font-weight-thin primary--text pl-4 pt-2"},[_vm._v(" "+_vm._s(_vm.product.name[_vm.productLanguage])+" ")]),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer')],1),_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"lg12":"","sm12":"","xs12":""}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-timeline',{attrs:{"align-top":"","dense":""}},[_c('v-timeline-item',{attrs:{"hide-dot":""}},[_c('v-row',{staticClass:"pt-1 mb-4"},[_c('v-col',{attrs:{"cols":"2"}},[(
                                            !_vm.loading &&
                                                _vm.draft &&
                                                _vm.permissions[
                                                    _vm.tenant
                                                ].includes('edit products')
                                        )?_c('v-btn',{attrs:{"tile":"","outlined":"","color":"primary","products":""},on:{"click":_vm.editDraft}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Entwurf bearbeiten ")],1):(
                                            !_vm.loading &&
                                                !_vm.draft &&
                                                _vm.permissions[
                                                    _vm.tenant
                                                ].includes('edit products')
                                        )?_c('v-btn',{attrs:{"tile":"","outlined":"","color":"primary"},on:{"click":function () { return _vm.$router.push(
                                                    '/products/edit/' +
                                                        _vm.product.id
                                                ); }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Entwurf erstellen ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col')],1)],1),(!_vm.loading && _vm.draft)?_c('v-timeline-item',{staticClass:"mt-4",attrs:{"color":"grey lighten-2","small":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"float-right"},[_c('v-btn',{attrs:{"icon":"","color":"red lighten-2"},on:{"click":function () { return _vm.showChanges(
                                                        _vm.product.id,
                                                        0 // 0=Draft version
                                                    ); }}},[_c('v-icon',[_vm._v("mdi-vector-difference-ab")])],1),(
                                                _vm.permissions[
                                                    _vm.tenant
                                                ].includes('edit products')
                                            )?_c('v-btn',{attrs:{"icon":"","color":"red lighten-2"},on:{"click":_vm.editDraft}},[_c('v-icon',[_vm._v("mdi-file-document-edit")])],1):_vm._e()],1)]),_c('v-col',{staticClass:"float-left",attrs:{"cols":"2"}},[_c('strong',[_vm._v("Entwurf")]),(_vm.draft)?_c('div',{staticClass:"text-caption"},[_c('strong',[_vm._v("Erstellt:")]),_vm._v(" "+_vm._s(_vm.draft.created_by && _vm.draft.created_by.name)+" "),_c('br'),_vm._v(" am "+_vm._s(_vm.moment(_vm.draft.created_at).format( "DD.MM.YYYY HH:mm" ))+" Uhr "),_c('br'),(
                                                _vm.permissions[
                                                    _vm.tenant
                                                ].includes('edit products')
                                            )?_c('v-btn',{staticClass:"mt-2 p-0",attrs:{"color":"error","outlined":"","x-small":""},on:{"click":_vm.deleteDraft}},[_vm._v(" Entwurf löschen ")]):_vm._e()],1):_vm._e()]),_c('v-col',{staticClass:"float-left"},[_c('strong',[_vm._v(" ")]),(_vm.draft)?_c('div',{staticClass:"text-caption"},[_c('strong',[_vm._v("Letzte Änderung:")]),_vm._v(" "+_vm._s(_vm.draft.lastChangeBy && _vm.draft.lastChangeBy.name)+" "),_c('br'),_vm._v(" am "+_vm._s(_vm.moment(_vm.draft.updated_at).format( "DD.MM.YYYY - HH:mm" ))+" Uhr "),_c('br'),(
                                                _vm.permissions[
                                                    _vm.tenant
                                                ].includes('edit products')
                                            )?_c('v-btn',{staticClass:"mt-2 p-0",attrs:{"color":"error","outlined":"","x-small":""},on:{"click":function () { return _vm.showLastChanges(
                                                        _vm.product.id,
                                                        0 // 0=Draft version
                                                    ); }}},[_vm._v(" Letzte Änderungen ansehen ")]):_vm._e()],1):_vm._e()])],1)],1):_vm._e(),_c('v-timeline-item',{staticClass:"mt-4",attrs:{"color":"success"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('span',{staticClass:"font-weight-bold text-caption"},[_vm._v(" "+_vm._s(_vm.moment(_vm.live.created_at).format( "DD.MM.YYYY" ))+" ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.moment(_vm.live.created_at).format( "HH:mm" ))+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"float-right"},[(_vm.olderVersions.length > 0)?_c('v-btn',{attrs:{"icon":"","color":"red lighten-2"},on:{"click":function () { return _vm.showChanges(
                                                        _vm.product.id,
                                                        _vm.live.version_id
                                                    ); }}},[_c('v-icon',[_vm._v("mdi-vector-difference-ab")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","color":"grey darken-1"},on:{"click":function () { return _vm.goToProduct(_vm.product.id); }}},[_c('v-icon',[_vm._v("mdi-feature-search")])],1)],1)]),_c('v-col',{staticClass:"float-left"},[_c('strong',[_vm._v(_vm._s(_vm.$t("Aktiv")))]),_c('div',{staticClass:"text-caption mb-2"},[_vm._v(" Aktiviert von: "),_c('b',[_vm._v(_vm._s(_vm.live.created_by && _vm.live.created_by.name))]),_c('br'),_vm._v(" am "+_vm._s(_vm.moment(_vm.live.created_at).format( "DD.MM.YYYY HH:mm" ))+" Uhr ")])])],1)],1),_vm._l((_vm.olderVersions),function(item,index){return _c('v-timeline-item',{key:item.version_id,staticClass:"mt-5",attrs:{"color":"grey lighten-2","small":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('span',{staticClass:"font-weight-bold text-caption"},[_vm._v(" "+_vm._s(_vm.moment(item.created_at).format( "DD.MM.YYYY" ))+" ")]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.moment(item.created_at).format( "HH:mm" ))+" ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"float-right"},[(item.version_id != 1)?_c('v-btn',{attrs:{"icon":"","color":"red lighten-2"},on:{"click":function () { return _vm.showChanges(
                                                        _vm.product.id,
                                                        item.version_id
                                                    ); }}},[_c('v-icon',[_vm._v("mdi-vector-difference-ab")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","color":"grey darken-1"},on:{"click":function () { return _vm.goToVersion(
                                                        _vm.product.id,
                                                        item.version_id
                                                    ); }}},[_c('v-icon',[_vm._v("mdi-feature-search")])],1),_c('v-btn',{attrs:{"icon":"","color":"grey darken-1"},on:{"click":function () { return _vm.revert(item.version_id); }}},[_c('v-icon',[_vm._v("mdi-undo-variant")])],1)],1)]),_c('v-col',{staticClass:"float-left"},[_c('strong',[_vm._v("Version #"+_vm._s(item.version_id))]),_c('div',{staticClass:"text-caption mb-2"},[_vm._v(" Aktiv vom "+_vm._s(_vm.moment(item.created_at).format( "DD.MM.YYYY HH:mm " ))+" Uhr - "+_vm._s(_vm.history[index] && _vm.moment( _vm.history[index] .created_at ).format("DD.MM.YYYY HH:mm"))+" Uhr "),_c('br'),_vm._v(" "+_vm._s(item.created_by && 'Erstellt von')+" "),_c('b',[_vm._v(_vm._s(item.created_by && item.created_by.name))])])])],1)],1)})],2)],1)],1)],1)],1),_c('changesDialog',{attrs:{"open":_vm.openChanges,"version-id":_vm.changesVersionId,"product-id":_vm.changesProductId,"product-name":_vm.product.name && _vm.product.name[_vm.productLanguage],"last-changes-only":_vm.onlyLastChanges},on:{"close":function($event){_vm.openChanges = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }