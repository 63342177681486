<template>
    <div>
        <v-breadcrumbs class="pl-1" :items="crumbs">
            <v-icon slot="divider">chevron_right</v-icon>
        </v-breadcrumbs>
        <v-card>
            <v-data-table
                :headers="headers"
                :items="accounts"
                class="elevation-1"
            >
                <template #top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{
                            $t("Usermanagement")
                        }}</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog v-model="dialog" max-width="500px">
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{ $t("Create account") }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form ref="editedItem">
                                    <v-card-title>
                                        <span class="text-h5">{{
                                            formTitle
                                        }}</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            editedItem.name
                                                        "
                                                        :error-messages="
                                                            nameErrors
                                                        "
                                                        label="Name"
                                                        @input="
                                                            $v.editedItem.name.$touch()
                                                        "
                                                        @blur="
                                                            $v.editedItem.name.$touch()
                                                        "
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            editedItem.email
                                                        "
                                                        :error-messages="
                                                            emailErrors
                                                        "
                                                        label="E-Mail"
                                                        @input="
                                                            $v.editedItem.email.$touch()
                                                        "
                                                        @blur="
                                                            $v.editedItem.email.$touch()
                                                        "
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            editedItem.password
                                                        "
                                                        :error-messages="
                                                            passwordErrors
                                                        "
                                                        label="Password"
                                                        type="password"
                                                        @input="
                                                            $v.editedItem.password.$touch()
                                                        "
                                                        @blur="
                                                            $v.editedItem.password.$touch()
                                                        "
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                >
                                                    <v-select
                                                        v-model="
                                                            editedItem.role
                                                        "
                                                        :items="roles"
                                                        label="Roles"
                                                        :error-messages="
                                                            roleErrors
                                                        "
                                                        persistent-hint
                                                        single-line
                                                        @input="
                                                            $v.editedItem.role.$touch()
                                                        "
                                                        @blur="
                                                            $v.editedItem.role.$touch()
                                                        "
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="error"
                                            text
                                            @click="close"
                                        >
                                            {{ "Abbrechen" }}
                                        </v-btn>
                                        <v-btn color="primary" @click="save">
                                            {{ "Speichern" }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template #no-data>
                    {{ $t("No users created") }}
                </template>
                <template #item.actions="{ item }">
                    <v-icon small class="mr-2" @click="deleteItem(item)">
                        mdi-delete-forever
                    </v-icon>
                    <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import axios from "axios";
import { api } from "~/config";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],

    validations: {
        editedItem: {
            name: { required },
            email: { required, email },
            password: { minLength: minLength(6) },
            role: { required }
        }
    },

    data: () => ({
        dialog: false,
        dialogDelete: false,
        accounts: [],
        crumbs: [
            {
                text: "Start",
                disabled: false,
                href: "/"
            },
            {
                text: "Accounts",
                disabled: true,
                href: "/products"
            }
        ],
        headers: [
            {
                text: "Name",
                align: "left",
                sortable: true,
                value: "name"
            },
            { text: "E-Mail", value: "email" },
            { text: "Roles", value: "role" },
            {
                text: "Actions",
                value: "actions",
                align: "right",
                sortable: false
            }
        ],
        roles: ["admin", "editor", "viewer"],
        editedIndex: -1,
        editedItem: {
            name: "",
            email: "",
            roles: null
        },
        defaultItem: {
            name: "",
            email: "",
            roles: null
        }
    }),

    computed: {
        ...mapGetters({
            tenant: "auth/tenant"
        }),
        formTitle() {
            return this.editedIndex === -1
                ? this.$t("New account")
                : this.$t("Edit account");
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.editedItem.name.$dirty) return errors;
            !this.$v.editedItem.name.required &&
                errors.push("Name erforderlich.");
            return errors;
        },
        passwordErrors() {
            const errors = [];
            if (!this.$v.editedItem.password.$dirty) return errors;
            !this.$v.editedItem.password.minLength &&
                errors.push("Passwort muss mindestens 6 Zeichen lang sein.");
            return errors;
        },
        roleErrors() {
            const errors = [];
            if (!this.$v.editedItem.role.$dirty) return errors;
            !this.$v.editedItem.role.required &&
                errors.push("Rolle erforderlich.");
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.editedItem.email.$dirty) return errors;
            !this.$v.editedItem.email.email &&
                errors.push("E-Mail Addresse ungültig.");
            !this.$v.editedItem.email.required &&
                errors.push("E-mail Addresse ist erforderlich.");
            return errors;
        }
    },

    watch: {
        dialog(val) {
            val || this.close();
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        async initialize() {
            await axios
                .get(api.path("users.list", { tenant: this.tenant }))
                .then(r => r.data)
                .then(items => {
                    this.accounts = items.data;
                });
        },

        closeDelete() {},

        async deleteItemConfirm(item) {
            const index = this.accounts.indexOf(item);
            await axios
                .delete(api.path("users.delete"), {
                    data: {
                        id: item.id
                    }
                })
                .then(() => this.accounts.splice(index, 1));
        },

        editItem(item) {
            this.editedIndex = this.accounts.indexOf(item);
            this.editedItem = Object.assign({}, item);
            if (Array.isArray(this.editedItem.role)) {
                this.editedItem.role = this.editedItem.role[0];
            }
            this.dialog = true;
        },

        async deleteItem(item) {
            confirm(this.$t("Are you sure you want to delete this item?")) &&
                (await this.deleteItemConfirm(item));
        },

        close() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },

        async save() {
            this.$v.$touch();

            if (!this.$v.editedItem.$invalid) {
                if (this.editedIndex > -1) {
                    await axios
                        .put(
                            api.path("users.update", {
                                id: this.editedItem.id
                            }),
                            {
                                ...this.editedItem,
                                tenant: this.tenant,
                                roles: [this.editedItem.role]
                            }
                        )
                        .then(() =>
                            Object.assign(
                                this.accounts[this.editedIndex],
                                this.editedItem
                            )
                        );
                } else {
                    await axios
                        .post(api.path("users"), {
                            ...this.editedItem,
                            tenant: this.tenant,
                            roles: [this.editedItem.role]
                        })
                        .then(() => this.accounts.push(this.editedItem));
                }
                this.close();
            }
        }
    }
};
</script>
