import axios from "axios";
import { api } from "~/config";
import * as types from "../mutation-types";

/**
 * Initial state
 */
export const state = {
    list: [],
    exports: [],
    productToEdit: {},
    searchResult: [],
    productEdited: {},
    listTotalCount: 0
};

/**
 * Mutations
 */
export const mutations = {
    [types.SET_PRODUCT_TO_EDIT](state, product) {
        state.productToEdit = product;
    },

    [types.UPDATE_PRODUCT_FIELDS](state, payload) {
        // have to deal with different value types
        // this is a bit of a mess but ok
        var field = {};
        if (Array.isArray(payload.value)) {
            if (typeof payload.value[0] === "object") {
                field[payload.field] = payload.value[0].recommended_product_id
                    ? payload.value.map(a => a.recommended_product_id)
                    : payload.value.map(a => a.id);
            } else {
                field[payload.field] = payload.value;
            }
        } else {
            if (
                typeof payload.value === "object" &&
                payload.value &&
                payload.value.id &&
                !payload.value.media
            ) {
                field[payload.field + "_id"] = payload.value.id;
            } else {
                field[payload.field] = payload.value;
            }
        }

        /* eslint-disable-next-line no-unused-vars */
        state.productEdited[payload.productId] = {
            ...state.productEdited[payload.productId],
            ...field
        };

        state.productToEdit[payload.field] = payload.value;
    },

    [types.RESET_EDITED_PRODUCT](state, productId) {
        state.productEdited[productId] = {};
    },

    [types.SET_PRODUCT_LIST](state, products) {
        products.forEach(function(element) {
            element.export_web_updating = false;
        });
        state.list = products;
    },

    [types.SET_PRODUCT_EXPORT_LIST](state, products) {
        state.exports = products;
    },

    [types.SET_PRODUCT_LIST_TOTAL_COUNT](state, count) {
        state.listTotalCount = count;
    },

    [types.SET_PRODUCT_SEARCH_RESULT](state, products) {
        state.searchResult = products;
    },

    [types.PRODUCT_LIST_SET_UPDATE_EXPORT_WEB](state, payload) {
        state.list.find(x => x.id === payload.productId).export_web_updating =
            payload.value;
    },

    [types.PRODUCT_LIST_SET_EXPORT_WEB](state, payload) {
        state.list.find(x => x.id === payload.productId).export_web =
            payload.value;
    },

    [types.COPY_PRODUCT_TO_EDIT](
        state,
        { product, fields, fromLanguage, toLanguage }
    ) {
        if (fields.length > 0) {
            if (fromLanguage && toLanguage) {
                fields.forEach(function(field) {
                    if (Array.isArray(state.productToEdit[field][toLanguage])) {
                        state.productToEdit[field][toLanguage] = [];
                        const newArray = JSON.parse(
                            JSON.stringify(product[field][fromLanguage])
                        );
                        state.productToEdit[field][toLanguage] = newArray;
                    } else {
                        state.productToEdit[field][toLanguage] =
                            product[field][fromLanguage];
                    }
                });
            } else {
                fields.forEach(function(field) {
                    state.productToEdit[field] = product[field];
                });
            }
        } else {
            Object.keys(product).forEach(p => {
                state.productToEdit[p] = product[p];
            });
        }
    }
};

/**
 * Actions
 */
export const actions = {
    updateProductFields({ commit }, payload) {
        commit(types.UPDATE_PRODUCT_FIELDS, payload);
    },

    async storeEdited({ commit }, payload) {
        await axios
            .put(api.path("products.update", payload), {
                ...state.productEdited[payload.id],
                mode: payload.mode
            })
            .then(r => r.data)
            .then(() => {
                commit(types.RESET_EDITED_PRODUCT, payload.id);
            });
    },

    async lockDraft(vars, id) {
        await axios.put(api.path("products.lockDraft", { id: id }));
    },

    async deployDraft(vars, id) {
        await axios.put(api.path("products.deployDraft", { id: id }));
    },

    async fetchProductToEdit({ commit }, payload) {
        commit(types.RESET_EDITED_PRODUCT, payload);
        commit(types.SET_PRODUCT_TO_EDIT, {});
        let params = {
            id: payload.id,
            version: payload.version
        };

        const url = payload.version
            ? api.path("products.version", params)
            : api.path("products.fetchDraft", params);
        await axios
            .get(url)
            .then(r => r.data)
            .then(product => {
                commit(types.SET_PRODUCT_TO_EDIT, product.data);
            });
    },

    async fetchProduct({ commit }, payload) {
        commit(types.RESET_EDITED_PRODUCT, payload);
        commit(types.SET_PRODUCT_TO_EDIT, {});
        let params = {
            id: payload.id,
            version: payload.version
        };

        const url = payload.version
            ? api.path("products.version", params)
            : api.path("products.detail", params);
        await axios
            .get(url)
            .then(r => r.data)
            .then(product => {
                commit(types.SET_PRODUCT_TO_EDIT, product.data);
            });
    },

    async setStatus({ commit }, payload) {
        commit(types.PRODUCT_LIST_SET_UPDATE_EXPORT_WEB, {
            productId: payload.item.id,
            value: true
        });

        await axios
            .put(
                api.path("products.update", {
                    id: payload.item.id
                }),
                {
                    export_web: payload.status
                }
            )
            .then(r => r.data)
            .then(() => {
                commit(types.PRODUCT_LIST_SET_UPDATE_EXPORT_WEB, {
                    productId: payload.item.id,
                    value: false
                });
                commit(types.PRODUCT_LIST_SET_EXPORT_WEB, {
                    productId: payload.item.id,
                    value: payload.status
                });
            })
            .finally(() => {
                commit(types.PRODUCT_LIST_SET_UPDATE_EXPORT_WEB, {
                    productId: payload.item.id,
                    value: false
                });
            });
    },

    async fetchList({ commit }, payload) {
        let appendUrl = "&tenant=" + payload.tenant;

        if (payload) {
            if (payload.page !== undefined) {
                appendUrl += "&page=" + payload.page;
            }

            if (payload.rowsPerPage !== undefined) {
                appendUrl += "&rows=" + payload.rowsPerPage;
            }

            if (payload.filter.length > 0) {
                let filters = payload.filter.map(filterItem => {
                    if (typeof filterItem === "object") {
                        return `&filter[${
                            filterItem.key
                        }]=${filterItem.value.join(",")}`;
                    } else {
                        return `&filter[${filterItem}]=true`;
                    }
                });
                appendUrl += filters.join("");
            }

            if (payload.brands.length > 0) {    
                appendUrl += `&filter[brand_id]=${payload.brands.join(',')}`;
            }

            if (payload.search && payload.search.length > 0) {
                appendUrl += "&filter[string]=" + payload.search;
            }
        }

        await axios
            .get(api.path("products.list") + appendUrl)
            .then(r => r.data)
            .then(products => {
                commit(types.SET_PRODUCT_LIST_TOTAL_COUNT, products.meta.total);
                commit(types.SET_PRODUCT_LIST, products.data);
            });
    },

    async fetchExportList({ commit }, payload) {
        let appendUrl = "";

        if (payload) {
            if (payload.columns !== undefined) {
                appendUrl +=
                    "&fields[products]=" +
                    payload.columns.join(",") +
                    ",product_number,packaging_size_id";
            }

            if (payload.tenants !== undefined) {
                appendUrl +=
                    "&filter[tenant_id]=" +
                    payload.tenants.map(tenant => tenant.id).join(",");
            }

            if (payload.language !== undefined) {
                appendUrl += "&language=" + payload.language;
            }
        }

        let data = [];

        await axios
            .get(api.path("products.export") + appendUrl)
            .then(r => r.data)
            .then(products => {
                // commit(types.SET_PRODUCT_LIST_TOTAL_COUNT, products.meta.total);
                commit(types.SET_PRODUCT_EXPORT_LIST, products.data);
            });

        return data;
    },

    async search({ commit }, payload) {
        let appendUrl;
        if (payload) {
            if (payload.page !== undefined) {
                appendUrl += "&page=" + payload.page;
            }

            if (payload.rowsPerPage !== undefined) {
                appendUrl += "&rows=" + payload.rowsPerPage;
            }

            if (payload.filter.length > 0) {
                appendUrl +=
                    "&filter[" +
                    payload.filter.join("]=true&filter[") +
                    "]=true";
            }

            if (payload.search.length > 0) {
                appendUrl += "&filter[string]=" + payload.search;
            }
        }

        await axios
            .get(api.path("products.list") + appendUrl)
            .then(r => r.data)
            .then(products => {
                commit(
                    types.SET_PRODUCT_LISTPRODUCT_SEARCH_RESULT,
                    products.data
                );
            });
    },

    resetEdited({ commit }, productId) {
        commit(types.RESET_EDITED_PRODUCT, productId);
    },

    async copyProductToEdit(
        { commit },
        { id, fields, fromLanguage, toLanguage, tenant }
    ) {
        let changedFields = [];

        if (fromLanguage && toLanguage) {
            commit(types.COPY_PRODUCT_TO_EDIT, {
                fields: fields,
                product: state.productToEdit,
                fromLanguage: fromLanguage,
                toLanguage: toLanguage
            });
            return;
        }

        let params = {
            id: id
        };

        let appendUrl = "?to-tenant=" + tenant;

        if (fields !== undefined && fields.length > 0) {
            appendUrl = appendUrl + "&fields[products]=" + fields.join();
            changedFields = fields;
        }

        await axios
            .get(api.path("products.copy", params) + appendUrl)
            .then(r => r.data)
            .then(product => {
                changedFields =
                    changedFields.length < 1
                        ? Object.keys(product.data)
                        : changedFields;
                changedFields.forEach(function(field) {
                    commit(types.UPDATE_PRODUCT_FIELDS, {
                        field: field,
                        value: product.data[field],
                        productId: state.productToEdit.id
                    });
                });

                commit(types.COPY_PRODUCT_TO_EDIT, {
                    fields: fields,
                    product: product.data
                });
            });
    }
};

/**
 * Getters
 */
export const getters = {
    productExports: state => state.exports,
    productList: state => state.list,
    productToEdit: state => state.productToEdit,
    listTotalCount: state => state.listTotalCount,
    searchResult: state => state.searchResult,
    productEdited: state => state.productEdited
};
