import axios from "axios";
import { api } from "~/config";
import * as types from "../mutation-types";

/**
 * Initial state
 */
export const state = {
    items: [],
    attributes: {},
    tenants: [],
    data: {
        "product-type": [],
        brand: [],
        purpose: [],
        "product-group-code": [],
        "storage-note": [],
        "disposal-note": [],
        "emergency-information": [],
        "organic-label": [],
        "application-scope": [],
        "application-site": [],
        characteristic: [],
        "effect-duration": [],
        "hazard-statement": [],
        "precautionary-statement": [],
        "hazard-symbol": [],
        "chemical-type": [],
        "task-area": [],
        "product-line": [],
        task: [],
        season: [],
        "product-group": [],
        distribution: [],
        tenant: [],
        "plant-group": [],
        plant: [],
        vat: []
    }
};

/**
 * Mutations
 */
export const mutations = {
    [types.MASTER_DATA_SET_ITEMS](state, items) {
        state.items = items;
    },

    [types.MASTER_DATA_SET_DATA](state, { entity, items }) {
        state.data[entity] = items;
    },

    [types.MASTER_DATA_SET_TENANTS](state, items) {
        state.tenants = items;
    },

    [types.MASTER_DATA_RESET_ITEMS](state) {
        state.items = [];
    },

    [types.MASTER_DATA_SET_ATTRIBUTES](state, items) {
        state.attributes = items;
    },

    [types.MASTER_DATA_ADD_ITEM](state, { item, entity }) {
        state.data[entity].push(item);
    },

    [types.MASTER_DATA_DELETE_ITEM](state, { item, entity }) {
        let index = state.data[entity].findIndex(p => {
            return p == item;
        });
        if (index > -1) {
            state.data[entity].splice(index, 1);
        }
    }
};

/**
 * Actions
 */
export const actions = {
    async getTenants({ commit }) {
        await axios
            .get(api.path("masterData.list") + "/" + "tenant")
            .then(r => r.data)
            .then(items => {
                commit(types.MASTER_DATA_SET_TENANTS, items.data);
            });
    },

    // eslint-disable-next-line no-unused-vars
    async getData({ commit }, { entity, tenant }) {
        await axios
            .get(api.path("masterData.list") + "/" + entity, {
                params: {
                    tenant: tenant
                }
            })
            .then(r => r.data)
            .then(items => {
                let payload = {
                    entity: entity,
                    items: items.data
                };
                commit(types.MASTER_DATA_SET_DATA, payload);
            });
    },

    async getAttributes({ commit }, { tenant, scope }) {
        await axios
            .get(
                api.path("masterData.listAll", { scope: scope, tenant: tenant })
            )
            .then(r => r.data)
            .then(items => {
                commit(types.MASTER_DATA_SET_ATTRIBUTES, items.data);
            });
    },

    async updateItem({ commit }, payload) {
        let updateFields = {};

        if (payload.fields === undefined) {
            updateFields = {
                ...payload.item
            };
        } else {
            updateFields = payload.fields;
        }

        await axios
            .put(
                api.path("masterData.update", {
                    id: payload.item.id,
                    entity: payload.entity
                }),
                updateFields
            )
            .then(r => r.data)
            .then(items => {
                commit(types.MASTER_DATA_SET_ATTRIBUTES, items.data);
            });
    },

    async saveNewItem({ commit }, payload) {
        await axios
            .post(
                api.path("masterData.createItem", {
                    entity: payload.entity
                }),
                {
                    ...payload.item
                }
            )
            .then(r => r.data)
            .then(item => {
                commit(types.MASTER_DATA_ADD_ITEM, {
                    item: item.data,
                    entity: payload.entity
                });
            });
    },

    async deleteItem({ commit }, payload) {
        await axios
            .delete(
                api.path("masterData.delete", {
                    id: payload.item.id,
                    entity: payload.entity
                })
            )
            .then(() => {
                commit(types.MASTER_DATA_DELETE_ITEM, {
                    item: payload.item,
                    entity: payload.entity
                });
            });
    }
};

/**
 * Getters
 */
export const getters = {
    items: state => state.items,
    itemsTotalCount: state => state.itemsTotalCount,
    attributes: state => state.attributes,
    tenants: state => state.tenants,
    "product-type": state => state.data["product-type"],
    brand: state => state.data.brand,
    purpose: state => state.data.purpose,
    "product-group-code": state => state.data["product-group-code"],
    "storage-note": state => state.data["storage-note"],
    "disposal-note": state => state.data["disposal-note"],
    "emergency-information": state => state.data["emergency-information"],
    "organic-label": state => state.data["organic-label"],
    "application-scope": state => state.data["application-scope"],
    "application-site": state => state.data["application-site"],
    characteristic: state => state.data["characteristic"],
    "effect-duration": state => state.data["effect-duration"],
    "hazard-statement": state => state.data["hazard-statement"],
    "precautionary-statement": state => state.data["precautionary-statement"],
    "hazard-symbol": state => state.data["hazard-symbol"],
    "chemical-type": state => state.data["chemical-type"],
    "task-area": state => state.data["task-area"],
    "product-line": state => state.data["product-line"],
    season: state => state.data["season"],
    task: state => state.data["task"],
    "product-group": state => state.data["product-group"],
    distribution: state => state.data["distribution"],
    plant: state => state.data["plant"],
    "plant-group": state => state.data["plant-group"],
    vat: state => state.data.vat
};
