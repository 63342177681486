<template>
    <v-card class="elevation-0">
        <v-card-title>
            <h3 class="text-h5 font-weight-thin primary--text pl-4 pt-2">
                {{ $t("Logistics") }}
            </h3>
        </v-card-title>
        <v-card-text>
            <v-container py-0 px-2 grid-list-xs fluid>
                <v-layout wrap>
                    <v-flex xs12>
                        <h4 class="text-h5 font-weight-thin primary--text">
                            {{ $t("Weights") }}
                        </h4>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                        <v-text-field
                            :value="product.weight_gross"
                            suffix="kg"
                            :label="$t('Gross Weight')"
                            :disabled="readonly"
                            readonly
                        >
                        <template #append>
                            <navisionIcon />
                        </template>
                    </v-text-field>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                        <v-text-field
                            :value="product.weight_net"
                            suffix="kg"
                            :label="$t('Net Weight')"
                            :disabled="readonly"
                            readonly
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                        <v-text-field
                            :value="product.weight_of_pallet"
                            suffix="kg"
                            :label="$t('Weight of Pallet')"
                            :disabled="readonly"
                            readonly
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                        <v-text-field
                            :value="product.dimensions"
                            :suffix="$t('in cm')"
                            :placeholder="$t('L x W x H')"
                            :label="$t('Dimensions')"
                            hint="Lange x Breite x Höhe in cm"
                            persistent-hint
                            :disabled="readonly"
                            readonly
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <h4 class="text-h5 font-weight-thin primary--text">
                            {{ $t("Quantities") }}
                        </h4>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                        <v-text-field
                            :value="product.quantity_per_package"
                            placeholder=" "
                            :label="$t('Quantity of package')"
                            :suffix="$t('Units')"
                            hint="CU pro TU"
                            persistent-hint
                            :disabled="readonly"
                            readonly
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                        <v-text-field
                            :value="product.quantity_of_pallet"
                            placeholder=" "
                            :label="$t('Quantity of pallet')"
                            :suffix="$t('Units')"
                            hint="Angabe in Sück"
                            :disabled="readonly"
                            readonly
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                        <v-text-field
                            :value="product.minimum_order_quantity"
                            placeholder=" "
                            :label="$t('Minimum order quantitiy')"
                            :suffix="$t('Units')"
                            hint="CU"
                            persistent-hint
                            :disabled="readonly"
                            readonly
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <h4 class="text-h5 font-weight-thin primary--text">
                            {{ $t("Stocks") }}
                        </h4>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                        <v-text-field
                            :value="product.inventory"
                            placeholder=" "
                            :label="$t('Inventory')"
                            suffix="kg"
                            :disabled="readonly"
                            readonly
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                        <v-text-field
                            :value="product.inventory_per_piece"
                            placeholder=" "
                            :label="$t('Inventory per piece')"
                            :suffix="$t('Units')"
                            :disabled="readonly"
                            readonly
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <h4 class="text-h5 font-weight-thin primary--text">
                            {{ $t("Numbers") }}
                        </h4>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                        <v-text-field
                            :value="product.customs_tariff_number"
                            placeholder=" "
                            :label="$t('Customs tariff number')"
                            :disabled="readonly"
                            readonly
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs6 sm4 md4>
                        <v-text-field
                            :value="product.ean_for_repackaging"
                            placeholder=" "
                            :label="$t('EAN for repackaging')"
                            :disabled="readonly"
                            readonly
                        >
                            <template #append>
                                <navisionIcon />
                            </template>
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12>
                        <h4 class="text-h5 font-weight-thin primary--text">
                            {{ $t("Transport") }}
                        </h4>
                    </v-flex>
                    <v-flex xs12 sm8 md8>
                        <translateable
                            :value="product.transport_regulations"
                            :default-language="language"
                            :multiline="false"
                            :readonly="readonly"
                            :label="$t('Transport regulations')"
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event,
                                    'transport_regulations'
                                )
                            "
                        ></translateable>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    components: {
        translateable: require("$comp/shared/TranslateableText").default,
        navisionIcon: require("$comp/ui/NavisionIcon").default
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        fallbackLanguage: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            attributes: "master-data/attributes"
        }),

        productGroup() {
            return this.product.product_group
                ? this.product.product_group.name
                : "";
        },

        productGroupCode() {
            return this.product.product_group_code
                ? this.product.product_group_code.name
                : "";
        }
    }
};
</script>
