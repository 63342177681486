<template>
    <v-flex sm8 md6 lg4>
        <v-card>
            <v-toolbar text text-xs-center height="200">
                <v-layout align-center>
                    <v-flex text-center>
                        <img height="60" src="/img/logo_hauert_gruen.svg" />
                        <br />
                        <h3 class="font-weight-light font-italic">
                            m i d d l e w a r e
                        </h3>
                    </v-flex>
                </v-layout>
            </v-toolbar>
            <v-card-text>
                <p>
                    {{
                        $t(
                            "Please enter your email address. You will receive a link to create a new password via email."
                        )
                    }}
                </p>
                <password-forgot-form @success="success"></password-forgot-form>
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
export default {
    components: {
        "password-forgot-form": require("./PasswordForgotForm").defalt
    },

    methods: {
        success() {
            this.$router.push({ name: "login" });
        }
    }
};
</script>
