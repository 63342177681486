<template>
    <v-dialog :value="dialog" max-width="820">
        <v-card>
            <v-toolbar color="primary" dense dark flat>
                <v-icon class="mr-2">mdi-vector-difference-ab</v-icon>
                <v-toolbar-title>Änderungen: {{ productName }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="mt-5">
                <v-card-title class="ma-0 pa-0 mb-2">
                    <div class="text-subtitle-1">
                        <strong v-if="versionId === 0 && lastChangesOnly"
                            >Letzte Änderungen im Entwurf</strong
                        >
                        <strong v-else-if="versionId === 0"
                            >Änderungen im Entwurf</strong
                        >
                        <strong v-else
                            >Änderungen zu Version #{{ versionId }}</strong
                        >
                        <br />
                        <span v-if="lastChangeBy">von {{ lastChangeBy }}</span>
                    </div>
                </v-card-title>

                <v-row v-for="key in orderedKeys" :key="key" no-gutters>
                    <v-col cols="2" class="pa-0 ma-0">
                        <v-alert
                            border="left"
                            color="grey darken-2"
                            text
                            class="mb-0 leftrow"
                            dense
                            tile
                        >
                            <div class="text-caption">
                                <strong>{{ $t(`diff_${key}`) }}</strong>
                            </div>
                        </v-alert>
                    </v-col>

                    <v-col cols="10">
                        <Fragment v-if="isObject(changes[key])">
                            <Fragment
                                v-for="value in filteredKeys(key)"
                                :key="value"
                            >
                                <v-row
                                    v-if="
                                        getDiff(
                                            oldProduct[key][value],
                                            changes[key][value]
                                        )
                                    "
                                    class="no-gutters"
                                >
                                    <v-col cols="6">
                                        <v-alert
                                            class="mb-0 leftrow text-caption"
                                            color="red lighten-2"
                                            text
                                            dense
                                            tile
                                        >
                                            <span
                                                class="font-weight-bold text-uppercase"
                                                >{{ value }} :</span
                                            >
                                            <span
                                                class="text-decoration-line-through"
                                            >
                                                {{ oldProduct[key][value]
                                                }}<br />
                                                {{ " " }}
                                            </span>
                                        </v-alert>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-alert
                                            class="mb-0 leftrow text-caption"
                                            color="green"
                                            text
                                            dense
                                            tile
                                        >
                                            <span
                                                class="font-weight-bold text-uppercase"
                                                >{{ value }} :</span
                                            >
                                            <span>
                                                {{ changes[key][value] }}<br />
                                                {{ " " }}
                                            </span>
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </Fragment>
                        </Fragment>
                        <Fragment v-else-if="oldProduct[key] != changes[key]">
                            <v-row class="no-gutters">
                                <v-col cols="6">
                                    <v-alert
                                        class="mb-0  leftrow text-caption text-decoration-line-through"
                                        color="red lighten-2"
                                        text
                                        dense
                                        tile
                                    >
                                        {{ oldProduct[key] }}&nbsp;
                                    </v-alert>
                                </v-col>
                                <v-col cols="6">
                                    <v-alert
                                        class="mb-0 leftrow text-caption"
                                        color="green"
                                        text
                                        dense
                                        tile
                                    >
                                        {{ changes[key] }}&nbsp;
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </Fragment>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="primary" outlined @click="close">
                    Schließen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
import { diff } from "deep-object-diff";
import { Fragment } from "vue-fragment";
import { api } from "~/config";

const changesOrder = [
    "product_number",
    "packaging_size_id",
    "tenant_id",
    "brand_id",
    "product_group_id",
    "product_group_code_id",
    "gtin",
    "name",
    "extended_name",
    "registry_numbers",
    "slug",
    "qr_code_url",
    "guiding_price",
    "unit_price",
    "vat",
    "vat_id",
    "image",
    "safety_data_sheet",
    "logo",
    "weight_gross",
    "weight_net",
    "weight_of_pallet",
    "dimensions",
    "quantity_per_package",
    "quantity_of_pallet",
    "minimum_order_quantity",
    "inventory",
    "inventory_per_piece",
    "customs_tariff_number",
    "ean_for_repackaging",
    "transport_regulations",
    "description_short",
    "features_text",
    "description_long",
    "application_text",
    "dosage_text",
    "dosage_exact",
    "dosage_unit",
    "dosage_min",
    "dosage_max",
    "take_note",
    "storage_note_id",
    "disposal_note_id",
    "emergency_information_id",
    "special_text",
    "marketing_text",
    "label_1_text",
    "label_2_text",
    "chemical_type_id",
    "season_ids",
    "effect_duration_id",
    "product_line_id",
    "grain_size",
    "distribution_id",
    "is_ec_fertilizer",
    "is_fibl_listed",
    "nutrients",
    "base_substances",
    "minor_components",
    "published_at"
];

export default {
    components: { Fragment },
    props: {
        open: {
            type: [Boolean],
            required: true
        },
        versionId: {
            type: [Number, Boolean],
            required: true
        },
        productId: {
            type: [Number, Boolean],
            required: true
        },
        productName: {
            type: String,
            required: true
        },
        lastChangesOnly: {
            type: [Boolean],
            required: true
        }
    },
    data: () => ({
        isLoading: false,
        tree: [],
        dialog: false,
        types: [],
        oldProduct: {},
        changes: {},
        lastChangeBy: ""
    }),

    computed: {
        orderedKeys() {
            const keys = Object.keys(this.changes);

            const newOrder = changesOrder
                .filter(item => keys.indexOf(item) > -1)
                .map(k => k);

            return newOrder;
        }
    },

    watch: {
        open(newVal) {
            this.dialog = newVal;
            this.fetchChanges(this.productId, this.versionId);
        },
        versionId(newVal) {
            this.fetchChanges(this.productId, newVal);
        },
        productId(newVal) {
            this.fetchChanges(newVal, this.versionId);
        }
    },

    methods: {
        filteredKeys(key) {
            const keys = Object.keys(this.changes[key]).filter(
                k => this.oldProduct[key][k] !== this.changes[key][k]
            );
            return keys;
        },
        getDiff(obj1, obj2) {
            const diffs = diff(obj1, obj2);
            if (
                diffs &&
                Object.keys(diffs).length === 0 &&
                Object.getPrototypeOf(diffs) === Object.prototype
            ) {
                return null;
            }
            return diffs;
        },
        getName(name) {
            return `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
        },
        isObject(data) {
            return typeof data === "object" && data !== null;
        },
        fetchChanges(productId, versionId) {
            this.changes = {};
            if (this.lastChangesOnly) {
                axios
                    .get(
                        api.path("products.lastChanges", {
                            id: productId
                        })
                    )
                    .then(response => {
                        this.oldProduct = response.data.data.old;
                        this.changes = response.data.data.changes;
                        this.lastChangeBy = response.data.data.changeBy.name;
                    });
            } else {
                axios
                    .get(
                        api.path("products.changes", {
                            id: productId,
                            version: versionId
                        })
                    )
                    .then(response => {
                        this.oldProduct = response.data.data.old;
                        this.changes = response.data.data.changes;
                    });
            }
        },
        close() {
            this.$emit("close");
        }
    }
};
</script>

<style>
.row {
    border-bottom: 1px solid #fff;
}

.leftrow {
    height: 100%;
}

.bbottom.before:not(:last-child) {
    border-bottom: 1px dashed darkgray;
}
.bbottom.after:not(:last-child) {
    border-bottom: 1px dashed darkgray;
}
</style>
