<template>
    <v-container fluid grid-list-xl class="mt-2 product-versions">
        <v-breadcrumbs class="mt-3 pl-1" :items="crumbs">
            <v-icon slot="divider">chevron_right</v-icon>
        </v-breadcrumbs>
        <v-card class="mx-auto elevation-1">
            <v-card-title>
                <h3 class="text-h5 font-weight-thin primary--text pl-4 pt-2">
                    {{ product.name[productLanguage] }}
                </h3>

                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-layout justify-center wrap>
                <v-flex lg12 sm12 xs12>
                    <v-card-text class="py-0">
                        <v-timeline align-top dense>
                            <v-timeline-item hide-dot>
                                <v-row class="pt-1 mb-4">
                                    <v-col cols="2">
                                        <v-btn
                                            v-if="
                                                !loading &&
                                                    draft &&
                                                    permissions[
                                                        tenant
                                                    ].includes('edit products')
                                            "
                                            tile
                                            outlined
                                            color="primary"
                                            products
                                            @click="editDraft"
                                        >
                                            <v-icon left>
                                                mdi-pencil
                                            </v-icon>
                                            Entwurf bearbeiten
                                        </v-btn>
                                        <v-btn
                                            v-else-if="
                                                !loading &&
                                                    !draft &&
                                                    permissions[
                                                        tenant
                                                    ].includes('edit products')
                                            "
                                            tile
                                            outlined
                                            color="primary"
                                            @click="
                                                () =>
                                                    $router.push(
                                                        '/products/edit/' +
                                                            product.id
                                                    )
                                            "
                                        >
                                            <v-icon left>
                                                mdi-pencil
                                            </v-icon>
                                            Entwurf erstellen
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="1"> </v-col>
                                    <v-col> </v-col>
                                </v-row>
                            </v-timeline-item>
                            <v-timeline-item
                                v-if="!loading && draft"
                                color="grey lighten-2"
                                class="mt-4"
                                small
                            >
                                <v-row>
                                    <v-col cols="1"> </v-col>
                                    <v-col cols="2">
                                        <span class="float-right">
                                            <v-btn
                                                icon
                                                color="red lighten-2"
                                                @click="
                                                    () =>
                                                        showChanges(
                                                            product.id,
                                                            0 // 0=Draft version
                                                        )
                                                "
                                            >
                                                <v-icon
                                                    >mdi-vector-difference-ab</v-icon
                                                >
                                            </v-btn>
                                            <v-btn
                                                v-if="
                                                    permissions[
                                                        tenant
                                                    ].includes('edit products')
                                                "
                                                icon
                                                color="red lighten-2"
                                                @click="editDraft"
                                            >
                                                <v-icon
                                                    >mdi-file-document-edit</v-icon
                                                >
                                            </v-btn>
                                        </span>
                                    </v-col>
                                    <v-col cols="2" class="float-left">
                                        <strong>Entwurf</strong>
                                        <div v-if="draft" class="text-caption">
                                            <strong>Erstellt:</strong>
                                            {{
                                                draft.created_by &&
                                                    draft.created_by.name
                                            }}
                                            <br />
                                            am
                                            {{
                                                moment(draft.created_at).format(
                                                    "DD.MM.YYYY HH:mm"
                                                )
                                            }}
                                            Uhr
                                            <br />
                                            <v-btn
                                                v-if="
                                                    permissions[
                                                        tenant
                                                    ].includes('edit products')
                                                "
                                                class="mt-2 p-0"
                                                color="error"
                                                outlined
                                                x-small
                                                @click="deleteDraft"
                                            >
                                                Entwurf löschen
                                            </v-btn>
                                        </div>
                                    </v-col>
                                    <v-col class="float-left">
                                        <strong>&nbsp;</strong>
                                        <div v-if="draft" class="text-caption">
                                            <strong>Letzte Änderung:</strong>
                                            {{
                                                draft.lastChangeBy &&
                                                    draft.lastChangeBy.name
                                            }}
                                            <br />
                                            am
                                            {{
                                                moment(draft.updated_at).format(
                                                    "DD.MM.YYYY - HH:mm"
                                                )
                                            }}
                                            Uhr
                                            <br />
                                            <v-btn
                                                v-if="
                                                    permissions[
                                                        tenant
                                                    ].includes('edit products')
                                                "
                                                class="mt-2 p-0"
                                                color="error"
                                                outlined
                                                x-small
                                                @click="
                                                    () =>
                                                        showLastChanges(
                                                            product.id,
                                                            0 // 0=Draft version
                                                        )
                                                "
                                            >
                                                Letzte Änderungen ansehen
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>

                            <v-timeline-item color="success" class="mt-4">
                                <v-row>
                                    <v-col cols="1">
                                        <span
                                            class="font-weight-bold text-caption"
                                        >
                                            {{
                                                moment(live.created_at).format(
                                                    "DD.MM.YYYY"
                                                )
                                            }}
                                        </span>
                                        <br />
                                        <span class="text-caption">
                                            {{
                                                moment(live.created_at).format(
                                                    "HH:mm"
                                                )
                                            }}
                                        </span>
                                    </v-col>
                                    <v-col cols="2">
                                        <div class="float-right">
                                            <v-btn
                                                v-if="olderVersions.length > 0"
                                                icon
                                                color="red lighten-2"
                                                @click="
                                                    () =>
                                                        showChanges(
                                                            product.id,
                                                            live.version_id
                                                        )
                                                "
                                            >
                                                <v-icon
                                                    >mdi-vector-difference-ab</v-icon
                                                >
                                            </v-btn>
                                            <v-btn
                                                icon
                                                color="grey darken-1"
                                                @click="
                                                    () =>
                                                        goToProduct(product.id)
                                                "
                                            >
                                                <v-icon
                                                    >mdi-feature-search</v-icon
                                                >
                                            </v-btn>
                                        </div>
                                    </v-col>
                                    <v-col class="float-left">
                                        <strong>{{ $t("Aktiv") }}</strong>
                                        <div class="text-caption mb-2">
                                            Aktiviert von:
                                            <b>{{
                                                live.created_by &&
                                                    live.created_by.name
                                            }}</b>
                                            <br />
                                            am
                                            {{
                                                moment(live.created_at).format(
                                                    "DD.MM.YYYY HH:mm"
                                                )
                                            }}
                                            Uhr
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>

                            <v-timeline-item
                                v-for="(item, index) in olderVersions"
                                :key="item.version_id"
                                color="grey lighten-2"
                                small
                                class="mt-5"
                            >
                                <v-row>
                                    <v-col cols="1">
                                        <span
                                            class="font-weight-bold text-caption"
                                        >
                                            {{
                                                moment(item.created_at).format(
                                                    "DD.MM.YYYY"
                                                )
                                            }}
                                        </span>
                                        <br />
                                        <span class="text-caption">
                                            {{
                                                moment(item.created_at).format(
                                                    "HH:mm"
                                                )
                                            }}
                                        </span>
                                    </v-col>
                                    <v-col cols="2">
                                        <div class="float-right">
                                            <v-btn
                                                v-if="item.version_id != 1"
                                                icon
                                                color="red lighten-2"
                                                @click="
                                                    () =>
                                                        showChanges(
                                                            product.id,
                                                            item.version_id
                                                        )
                                                "
                                            >
                                                <v-icon
                                                    >mdi-vector-difference-ab</v-icon
                                                >
                                            </v-btn>
                                            <v-btn
                                                icon
                                                color="grey darken-1"
                                                @click="
                                                    () =>
                                                        goToVersion(
                                                            product.id,
                                                            item.version_id
                                                        )
                                                "
                                            >
                                                <v-icon
                                                    >mdi-feature-search</v-icon
                                                >
                                            </v-btn>
                                            <v-btn
                                                icon
                                                color="grey darken-1"
                                                @click="
                                                    () =>
                                                        revert(item.version_id)
                                                "
                                            >
                                                <v-icon
                                                    >mdi-undo-variant</v-icon
                                                >
                                            </v-btn>
                                        </div>
                                    </v-col>
                                    <v-col class="float-left">
                                        <strong
                                            >Version #{{
                                                item.version_id
                                            }}</strong
                                        >
                                        <div class="text-caption mb-2">
                                            Aktiv vom
                                            {{
                                                moment(item.created_at).format(
                                                    "DD.MM.YYYY HH:mm "
                                                )
                                            }}
                                            Uhr -
                                            {{
                                                history[index] &&
                                                    moment(
                                                        history[index]
                                                            .created_at
                                                    ).format("DD.MM.YYYY HH:mm")
                                            }}
                                            Uhr
                                            <br />
                                            {{
                                                item.created_by &&
                                                    'Erstellt von'
                                            }}
                                            <b>{{
                                                item.created_by &&
                                                    item.created_by.name
                                            }}</b>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                        </v-timeline>
                    </v-card-text>
                </v-flex>
            </v-layout>
        </v-card>
        <changesDialog
            :open="openChanges"
            :version-id="changesVersionId"
            :product-id="changesProductId"
            :product-name="product.name && product.name[productLanguage]"
            :last-changes-only="onlyLastChanges"
            @close="openChanges = false"
        />
    </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { api, defaultLanguageIso } from "~/config";

export default {
    components: {
        changesDialog: require("$comp/product/versions/ChangesDialog").default
    },

    props: {
        product: {
            type: [Object],
            required: false,
            default: () => {}
        },
        live: {
            type: [Object],
            required: false,
            default: () => {}
        },
        draft: {
            type: [Object],
            required: false,
            default: () => {}
        },
        history: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            productLanguage: defaultLanguageIso,
            moment: moment,
            changesVersionId: false,
            openChanges: false,
            changesProductId: false,
            onlyLastChanges: false
        };
    },

    computed: {
        ...mapGetters({
            permissions: "auth/permissions",
            tenant: "auth/tenant",
            loading: "ui/loading"
        }),
        olderVersions() {
            return this.history.slice(1);
        },
        crumbs() {
            const label = this.product ? this.product.item_number : "";
            return [
                {
                    text: this.$t("Dashboard"),
                    disabled: false,
                    href: "/"
                },
                {
                    text: this.$t("Products"),
                    disabled: false,
                    href: "/products"
                },
                {
                    text: label,
                    disabled: true,
                    href: "/products/" + this.$route.params.id
                },
                {
                    text: this.$t("Versions"),
                    disabled: true,
                    href: "/products/" + this.$route.params.id
                }
            ];
        }
    },

    created() {},

    methods: {
        ...mapActions({
            setLoading: "ui/setLoading"
        }),
        showChanges(product, version) {
            this.changesVersionId = version;
            this.changesProductId = product;
            this.onlyLastChanges = false;
            this.openChanges = true;
        },
        showLastChanges(product, version) {
            this.changesVersionId = version;
            this.changesProductId = product;
            this.onlyLastChanges = true;
            this.openChanges = true;
        },
        goToProduct(product) {
            this.$router.push({
                name: "product-view",
                params: { id: product }
            });
        },
        goToVersion(product, version) {
            this.$router.push({
                name: "product-view-version",
                params: { product: product, version: version }
            });
        },
        async revert(version) {
            if (
                confirm(
                    "Die Version #" +
                        version +
                        " wirklich als aktuelle Version verwenden?"
                )
            ) {
                await axios
                    .put(
                        api.path("products.revert", {
                            id: this.product.id,
                            version: version
                        })
                    )
                    .then(() => {
                        this.$emit("change");
                        this.$store.dispatch("ui/showSnackbar", {
                            text: this.$t(
                                "Eine neue Version mit den Daten von Version #" +
                                    version +
                                    " wurde erstellt!"
                            ),
                            color: "success"
                        });
                    });
            }
        },
        async deleteDraft() {
            if (confirm("Den aktuellen Entwurf wirklich löschen?")) {
                await axios
                    .delete(
                        api.path("products.deleteDraft", {
                            id: this.product.id
                        })
                    )
                    .then(() => {
                        this.$emit("change");
                        this.$store.dispatch("ui/showSnackbar", {
                            text: this.$t("Entwurf wurde gelöscht"),
                            color: "success"
                        });
                    });
            }
        },
        async editDraft() {
            const { data } = await axios.get(
                api.path("products.checkEditable", { id: this.product.id })
            );
            if (data.editable && data.editable === true) {
                this.$router.push("/products/edit/" + this.product.id);
            } else {
                this.$store.dispatch("ui/showSnackbar", {
                    text: this.$t(
                        "Produkt wird bereits von einem anderen Nutzer bearbeitet!"
                    ),
                    color: "error"
                });
                this.$router.push(
                    "/products/view/" + this.product.id + "/version/0"
                );
            }
        }
    }
};
</script>
