/* eslint-disable vue/no-unused-vars */
<template>
    <div>
        <v-dialog
            v-model="dialog"
            scrollable
            persistent
            :max-width="dialogWidth"
        >
            <template #activator="{ on }">
                <slot name="opener" :on="on">
                    <v-layout>
                        <v-flex xs12>
                            <v-btn
                                v-if="activatorLabel"
                                class="btn-activator"
                                outlined
                                color="primary"
                                :disabled="readonly"
                                dark
                                v-on="on"
                                >{{ activatorLabel }}</v-btn
                            >
                            <v-btn
                                v-else
                                class="btn-activator"
                                fab
                                text
                                outlined
                                :disabled="readonly"
                                dark
                                small
                                color="primary"
                                v-on="on"
                            >
                                <v-icon dark>{{ selectorIcon }}</v-icon>
                            </v-btn>
                        </v-flex>
                        <v-flex xs12>
                            <ul>
                                <v-list dense>
                                    <template v-for="(item, i) in selected">
                                        <v-list-item :key="`${i}-${item.id}`">
                                            <v-list-item-avatar
                                                :tile="true"
                                                size="48"
                                                @click.stop="
                                                    showDetail(item.media.large)
                                                "
                                            >
                                                <img :src="item.media.thumb" />
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title>{{
                                                    item.title
                                                }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </ul>
                        </v-flex>
                    </v-layout>
                </slot>
            </template>

            <v-card class="mediavalet-selector">
                <v-card-title class="selector-toolbar px-0 py-0">
                    <v-toolbar text height="85" light color="secondary">
                        <v-combobox
                            v-model="select"
                            label="Search in MediaValet"
                            prepend-icon="image_search"
                            multiple
                            chips
                            :loading="isLoading"
                            @change="search"
                        ></v-combobox>
                    </v-toolbar>
                </v-card-title>

                <v-slide-y-transition>
                    <v-card-text
                        v-show="items.length > 0 || selected.length > 0"
                    >
                        <v-layout>
                            <v-flex :class="columnWith">
                                <v-list two-line>
                                    <v-subheader>
                                        {{ $t("Available items") }}
                                    </v-subheader>
                                    <template v-for="(item, i) in items">
                                        <v-list-item
                                            :key="`${i}-${item.id}`"
                                            @click="toggle(item)"
                                        >
                                            <v-list-item-avatar
                                                :tile="true"
                                                size="48"
                                                @click="
                                                    showDetail(item.media.large)
                                                "
                                            >
                                                <img :src="item.media.thumb" />
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-tooltip top>
                                                    <v-list-item-title>
                                                        {{ item.title }}
                                                    </v-list-item-title>
                                                    <span>{{
                                                        item.title
                                                    }}</span>
                                                </v-tooltip>

                                                <v-list-item-subtitle>
                                                    <span class="black--text">
                                                        {{ $t("File type") }}
                                                    </span>
                                                    {{ item.file.fileType }}
                                                    <span class="black--text">
                                                        {{ $t("Modified at") }}
                                                    </span>
                                                    {{
                                                        moment(
                                                            item.file.modifiedAt
                                                        ).format("DD.MM.YYYY")
                                                    }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                    {{ item.description }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-icon
                                                    v-if="
                                                        selected.findIndex(
                                                            i =>
                                                                i.id === item.id
                                                        ) < 0
                                                    "
                                                    color="grey lighten-1"
                                                    >check_circle_outline</v-icon
                                                >

                                                <v-icon
                                                    v-else
                                                    color="primary darken-2"
                                                    >check_circle</v-icon
                                                >
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-flex>

                            <v-flex v-show="multiple" :class="columnWith">
                                <v-divider
                                    v-show="multiple"
                                    class="float-left"
                                    vertical
                                ></v-divider>

                                <v-list two-line>
                                    <v-subheader>
                                        {{ $t("Selected items") }}
                                    </v-subheader>
                                    <template v-for="(item, i) in selected">
                                        <v-list-item
                                            :key="`${i}-${item.id}`"
                                            dark
                                            @click="toggle(item)"
                                        >
                                            <v-list-item-avatar
                                                :tile="true"
                                                size="48"
                                                @click="
                                                    showDetail(item.media.large)
                                                "
                                            >
                                                <img :src="item.media.thumb" />
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-tooltip top>
                                                    <v-list-item-title>
                                                        {{ item.title }}
                                                    </v-list-item-title>
                                                    <span>{{
                                                        item.title
                                                    }}</span>
                                                </v-tooltip>

                                                <v-list-item-subtitle>
                                                    <span class="black--text">
                                                        {{ $t("File type") }}
                                                    </span>
                                                    {{ item.file.fileType }}
                                                    <span class="black--text">
                                                        {{ $t("Modified at") }}
                                                    </span>
                                                    {{
                                                        moment(
                                                            item.file.modifiedAt
                                                        ).format("DD.MM.YYYY")
                                                    }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                    {{ item.description }}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                                <v-btn
                                                    icon
                                                    text
                                                    color="grey"
                                                    class="mx-0"
                                                >
                                                    <v-icon
                                                        color="accent lighten-2"
                                                        >cancel</v-icon
                                                    >
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-slide-y-transition>

                <v-list
                    v-show="!multiple && selected.length > 0"
                    dark
                    two-line
                    xs12
                >
                    <v-subheader>{{ $t("Selected item") }}</v-subheader>
                    <template v-for="(item, i) in selected">
                        <v-list-item :key="`${i}-${item.title}`">
                            <v-list-item-avatar
                                :tile="true"
                                size="48"
                                @click="showDetail(item.media.large)"
                            >
                                <img :src="item.media.thumb" />
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-tooltip top>
                                    // eslint-disable-next-line
                                    vue/no-unused-vars
                                    <v-list-item-title>
                                        {{ item.title }}
                                    </v-list-item-title>
                                    <span>{{ item.title }}</span>
                                </v-tooltip>
                                <v-list-item-subtitle>
                                    <span class="black--white bold--text">
                                        {{ $t("File type") }}
                                    </span>
                                    {{ item.file.fileType }}
                                    <span class="black--white bold--text">
                                        {{ $t("Modified at") }}
                                    </span>
                                    {{
                                        moment(item.file.modifiedAt).format(
                                            "DD.MM.YYYY"
                                        )
                                    }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    {{ item.description }}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-btn
                                    icon
                                    text
                                    color="grey"
                                    class="mx-0"
                                    @click="clearSelected()"
                                >
                                    <v-icon color="accent lighten-2"
                                        >cancel</v-icon
                                    >
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-list>

                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="success darken-3" text @click="saveAndClose">
                        {{ $t("Save") }}
                    </v-btn>
                    <v-btn color="error darken-1" text @click="cancel">
                        {{ $t("Cancel") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="full">
            <v-card>
                <v-img :src="lightboxSrc" style="max-height:70%"></v-img>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="full = false"
                        >Close</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
    props: {
        activatorLabel: {
            type: [String, Boolean],
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        lang: {
            type: [String, Boolean],
            default: false
        },
        value: {
            type: Object,
            default: () => {}
        },
        accept: {
            type: [Boolean, Array],
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            select: "",
            dialog: false,
            isLoading: false,
            model: {},
            full: false,
            lightboxSrc: "",
            moment: moment,
            v: { ...this.value },
            initial: { ...this.value }
        };
    },
    computed: {
        ...mapGetters({
            items: "mediavalet/lastResult"
        }),

        selected: function() {
            if (
                !this.v ||
                (Array.isArray(this.v) && this.v.length == 0) ||
                Object.keys(this.v).length < 1 ||
                (this.lang && !this.v[this.lang])
            )
                return [];

            if (this.lang) {
                if (Array.isArray(this.v[this.lang])) {
                    return this.v[this.lang];
                } else {
                    return [this.v[this.lang]];
                }
            } else {
                if (Array.isArray(this.v)) {
                    return this.v;
                } else {
                    return [this.v];
                }
            }
        },

        selectorIcon() {
            if (this.multiple === false && this.selected.length > 0) {
                return "edit";
            } else {
                return "add";
            }
        },

        columnWith() {
            return this.multiple === true ? "xs6" : "xs12";
        },

        dialogWidth() {
            return this.multiple === true ? "980px" : "750px";
        }
    },
    watch: {
        value() {
            this.v = { ...this.value };
        }
    },

    methods: {
        ...mapActions({
            searchMediaValet: "mediavalet/search"
        }),

        search: function(values) {
            this.isLoading = true;
            this.searchMediaValet(values).then(() => {
                this.isLoading = false;
            });
        },

        saveAndClose() {
            this.$emit("change", this.v);
            this.dialog = false;
        },

        cancel() {
            this.dialog = false;
        },

        clearSelected() {
            if (this.lang) {
                let value = { ...this.v };
                value[this.lang] = null;
                this.$emit("input", value);
            } else {
                this.$emit("input", null);
            }
        },

        toggle: function(item) {
            let value = { ...this.v };

            const selectedIndex = this.selected.findIndex(
                i => i.id === item.id
            );

            if (this.accept) {
                let filter = this.accept.map(function(x) {
                    return x.toUpperCase();
                });
                let ind = filter.indexOf(item.file.fileType.toUpperCase());
                if (selectedIndex === -1 && ind < 0) {
                    this.$store.dispatch("ui/showSnackbar", {
                        text:
                            this.$t("Nur folgende Dateitypen zugelassen: ") +
                            filter.toString(),
                        color: "error"
                    });
                    return false;
                }
            }

            if (this.multiple) {
                const i = this.selected.findIndex(i => i.id === item.id); // 3

                if (i > -1) {
                    this.selected.splice(i, 1);
                } else {
                    this.selected.push(item);
                }
                if (this.lang) {
                    value[this.lang] = this.selected;
                } else {
                    value = this.selected;
                }
                this.$emit("input", value);
            } else {
                if (this.selected === item) {
                    this.$emit("input", []);
                } else {
                    this.$emit("input", []);
                    if (this.lang) {
                        value[this.lang] = item;
                    } else {
                        value = item;
                    }
                    this.$emit("input", value);
                }
            }
        },

        openDialog() {
            this.dialog = true;
        },

        showDetail: function(src) {
            this.lightboxSrc = src;
            this.full = true;
        },

        clicker(e) {
            alert(e);
        }
    }
};
</script>

<style scoped>
div.selector-toolbar {
    border-top: 6px solid var(--v-primary-lighten1);
}
.btn-activator {
    margin-left: -2px;
}
</style>
