<template>
    <v-form
        ref="form"
        v-model="valid"
        text
        lazy-validation
        @submit.prevent="submit"
    >
        <v-text-field
            v-model="form.email"
            :label="labels.email"
            type="email"
            :error-messages="errors.email"
            :rules="[rules.required('email')]"
            :disabled="loading"
            prepend-icon="person"
        ></v-text-field>

        <v-text-field
            v-model="form.password"
            :label="labels.password"
            :append-icon="passwordHidden ? 'visibility_off' : 'visibility'"
            :type="passwordHidden ? 'password' : 'text'"
            :error-messages="errors.password"
            :disabled="loading"
            :rules="[rules.required('password')]"
            prepend-icon="lock"
            @click:append="() => (passwordHidden = !passwordHidden)"
        ></v-text-field>

        <v-layout class="mt-2">
            <v-spacer></v-spacer>
            <!--
            <v-btn
                text
                :disabled="loading"
                :to="{ name: 'forgot', query: { email: form.email } }"
                color="grey darken-2"
                >{{ $t("Forgot password?") }}</v-btn
            > -->

            <v-btn
                type="submit"
                :loading="loading"
                :disabled="loading"
                color="primary"
                >Login</v-btn
            >
        </v-layout>
    </v-form>
</template>

<script>
import axios from "axios";
import { api } from "~/config";
import Form from "~/mixins/form";

export default {
    mixins: [Form],

    data: () => ({
        passwordHidden: true,

        form: {
            email: null,
            password: null
        },

        labels: {
            email: null,
            password: null
        }
    }),

    created() {
        this.form.email = this.$route.query.email || null;
        this.labels.email = this.$t("E-Mail");
        this.labels.password = this.$t("Password");
    },

    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.loading = true;

                axios
                    .post(api.path("login"), this.form)
                    .then(res => {
                        this.$store.dispatch("ui/showSnackbar", {
                            text: this.$t("Welcome back!"),
                            color: "success",
                            top: true,
                            right: true,
                            timeout: 3000
                        });
                        this.$emit("success", res.data.data);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    }
};
</script>
