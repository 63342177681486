<template>
  <v-container fluid grid-list-xl>
      <v-layout justify-center wrap fill-height>
          <v-flex lg12 sm12 xs12>
              <v-breadcrumbs class="pl-1" :items="crumbs">
                  <v-icon slot="divider">chevron_right</v-icon>
              </v-breadcrumbs>

              <v-card height="100%">
                <v-card-title>
                  <h3 class="text-h5 font-weight-thin primary--text pt-2 mb-5">
                    {{ $t("Bulk update products") }}
                  </h3>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="6" >
                          <v-file-input
                            v-model="chosenFile"
                            accept=".csv"
                            :label="$t('Select CSV File')"
                            prepend-icon="mdi-file-delimited"
                          />
                        </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <!-- eslint-disable vue/no-v-html -->
                        <div class="ml-8" v-html="responseHtml" />
                        <!--eslint-enable-->
                      </v-col>
                    </v-row>
                </v-card-text>
                  <v-card-actions>
                      <v-btn
                          class="ml-11 mt-5 ml-2 mb-5"
                          color="primary"
                          :loading="loading"
                          @click="submitFile"
                          >Upload</v-btn
                      >
                  </v-card-actions>
              </v-card>
          </v-flex>
      </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { api } from "~/config";

export default {
  data() {
      return {
          loading: false,
          chosenFile: {},
          responseHtml: "",
          crumbs: [
              {
                  text: this.$t("Dashboard"),
                  disabled: false,
                  href: "/"
              },
              {
                  text: this.$t("Products"),
                  disabled: false,
                  href: "/products"
              },
              {
                  text: this.$t("Bulk update"),
                  disabled: true,
                  href: "/products/export"
              }
          ]
      };
  },
  computed: {
      ...mapGetters({})
  },

  methods: {
      ...mapActions({}),
      async submitFile() {
        try {
          this.responseHtml = ""
          this.loading = true
          const formData = new FormData();
          formData.append('csvFile', this.chosenFile);
          const headers = { 'Content-Type': 'multipart/form-data' };
          const { data, status } = await axios.post(
                api.path("products.bulkUpdate"),
                formData,
                { headers }
          );
          if(status === 200){
            this.responseHtml = data
          } else {
            this.responseHtml = "Upload ERROR"
          }

        } catch (e) {
          // console.log(e)
        }
        this.loading = false

      }
  }
};
</script>
