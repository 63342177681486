<template>
    <v-container fluid grid-list-xl>
        <v-layout justify-center wrap>
            <v-flex lg12 sm12 xs12>
                <v-breadcrumbs class="pl-1" :items="crumbs">
                    <v-icon slot="divider">chevron_right</v-icon>
                </v-breadcrumbs>
                <v-card>
                    <v-card-title class="pl-0">
                        <h3
                            class="text-h5 font-weight-thin primary--text pl-4 pt-2"
                        >
                            {{ $t("Products") }}
                        </h3>

                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>

                        <productFilter v-model="filter" :selected-brands="brandFilter" @brand-select="brandSelect" @input="triggerFilter"  />
                        <v-text-field
                            :value="productFilter"
                            label="Search"
                            single-line
                            hide-details
                            clearable
                            @input="setProductFilter"
                            @change="updateProductFilter"
                            @click:clear="updateProductFilter('')"
                        >
                            <v-btn
                                slot="append"
                                icon
                                rounded
                                color="primary"
                                @click="triggerFilter"
                            >
                                <v-icon>search</v-icon>
                            </v-btn>
                        </v-text-field>
                    </v-card-title>
                    <v-divider class="mt-5"></v-divider>
                    <v-data-table
                        :headers="headers"
                        :items="products"
                        :options.sync="pagination"
                        :server-items-length="listTotalCount"
                        :loading="loading === true ? 'primary' : false"
                        class="elevation-0"
                    >
                        <template #item.item_number="props">{{
                            props.item.item_number
                        }}</template>

                        <template #item.export_web="props">
                            <v-btn
                                v-if="props.item.export_web"
                                fab
                                small
                                class="action-btn elevation-0"
                                :loading="props.item.export_web_updating"
                                @click.prevent="toggleStatus(props.item)"
                            >
                                <v-icon color="green">check_circle</v-icon>
                            </v-btn>
                            <v-btn
                                v-else
                                :loading="props.item.export_web_updating"
                                fab
                                small
                                class="action-btn elevation-0"
                                @click.prevent="toggleStatus(props.item)"
                            >
                                <v-icon color="error">cancel</v-icon>
                            </v-btn>
                        </template>

                        <template #item.ready_translations="props">
                            <span
                                v-for="lang in props.item.tenant.settings
                                    .product_validation.mandatory_languages"
                                :key="lang"
                                :class="
                                    `flag-check flag-icon flag-icon-` +
                                        lang +
                                        (props.item.ready_translations.indexOf(
                                            lang
                                        ) < 0
                                            ? ` flag-inactive`
                                            : ``)
                                "
                            ></span>
                        </template>

                        <template #item.tenant.name="props">{{
                            props.item.tenant.name[
                                props.item.tenant.settings.product_validation
                                    .default_language
                            ]
                        }}</template>

                        <template #item.name="props">{{
                            props.item.name[
                                props.item.tenant.settings.product_validation
                                    .default_language
                            ]
                        }}</template>

                        <template #item.action="props">
                            <v-btn
                                fab
                                small
                                light
                                class="mr-2 action-btn elevation-0"
                                @click="
                                    $router.push({
                                        name: 'product-versions',
                                        params: { id: props.item.id }
                                    })
                                "
                            >
                                <v-icon small color="grey darken-3"
                                    >history</v-icon
                                >
                            </v-btn>
                            <v-btn
                                fab
                                small
                                light
                                class="action-btn elevation-0"
                                @click="
                                    $router.push({
                                        name: 'product-view',
                                        params: { id: props.item.id }
                                    })
                                "
                            >
                                <v-icon small color="grey darken-3"
                                    >keyboard_arrow_right</v-icon
                                >
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultLanguageIso, mandatoryLanguages } from "~/config";

export default {
    components: {
        productFilter: require("./ProductListFilter").default
    },
    data() {
        return {
            crumbs: [
                {
                    text: this.$t("Start"),
                    disabled: false,
                    href: "/"
                },
                {
                    text: this.$t("Products"),
                    disabled: true,
                    href: "/products"
                }
            ],
            search: "",
            loading: true,
            loadingStatusUpdates: [],
            pagination: {
                rowsPerPage: 25
            },
            filter: [],
            brandFilter: [],
            dialog: false,
            defaultLanguageIso: defaultLanguageIso,
            mandatoryLanguages: mandatoryLanguages,
            headers: [
                {
                    text: this.$t("Item number"),
                    value: "item_number",
                    sortable: false
                },
                {
                    text: this.$t("Status web"),
                    value: "export_web",
                    sortable: false
                },
                {
                    text: this.$t("Ready translations"),
                    value: "ready_translations",
                    sortable: false
                },
                {
                    text: this.$t("Tenant"),
                    value: "tenant.name",
                    sortable: false
                },
                { text: this.$t("Name"), value: "name", sortable: false },
                {
                    text: this.$t("Action"),
                    value: "action",
                    align: "right",
                    sortable: false
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            products: "products/productList",
            listTotalCount: "products/listTotalCount",
            productFilter: "ui/productFilter",
            tenants: "master-data/tenants",
            tenant: "auth/tenant"
        })
    },

    watch: {
        pagination: {
            handler() {
                this.getRecords();
            },
            deep: false
        },

        loadingStatusUpdates: {
            handler: function() {},
            deep: true
        },

        productFilter: {
            handler() {
                // this.getRecords();
            }
        }
    },

    created() {
        this.getTenants();
    },

    methods: {
        ...mapActions({
            fetchList: "products/fetchList",
            setStatus: "products/setStatus",
            setProductFilter: "ui/setProductFilter",
            getTenants: "master-data/getTenants"
        }),

        getRecords: function() {
            let payload = Object.assign({}, this.pagination, {
                brands: this.brandFilter,
                search: this.productFilter,
                filter: this.filter,
                tenant: this.tenant
            });

            this.loading = true;
            this.fetchList(payload).then(() => {
                this.loading = false;
            });
        },

        editItem: function(item) {
            this.$router.push({
                name: "product-edit",
                params: { id: item.id }
            });
        },

        stopEditItem: function() {
            this.dialog = false;
        },

        triggerFilter: function() {
            this.getRecords();
        },

        brandSelect: function (value) {
            this.brandFilter = value;
            this.getRecords();
        },

        updateProductFilter(value) {
            this.setProductFilter(value);
            this.getRecords();
        },

        toggleStatus(item) {
            let payload = { item: item, status: true };
            if (item.export_web === true) {
                payload = { item: item, status: false };
            }
            this.setStatus(payload);
        }
    }
};
</script>

<style scoped>
.action-btn {
    width: 22px;
    height: 22px;
}
.flag-inactive {
    -webkit-filter: grayscale(1) brightness(2.4);
    filter: grayscale(1) brightness(2.4);
}

.flag-check {
    margin: 0 6px;
}
</style>
