<template>
    <v-layout justify-end mx-5 mb-2>
        <v-flex sm11 xs12>
            <v-card :elevation="0">
                <v-card-title>
                    <h6 class="text-h6 font-weight-thin primary--text">
                        {{ $t("Printdata export") }}
                    </h6>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="10">
                            <v-autocomplete
                                v-model="filter.products"
                                :items="products"
                                attach
                                chips
                                :label="$t('Products')"
                                multiple
                                :search-input.sync="search"
                                :loading="searchLoading"
                                item-value="id"
                                :filter="filterProducts"
                                clearable
                                cache-items
                                deletable-chips
                                :placeholder="
                                    $t(
                                        'Mit der Eingabe beginnen um zu suchen ...'
                                    )
                                "
                                :item-text="`item_number`"
                            >
                                <template #no-data>
                                    <v-list-item>
                                        <v-list-item-title>
                                            {{
                                                $t(
                                                    "Start typing to search products."
                                                )
                                            }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                                <template #item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-text="item.name[selectedLanguage]"
                                        ></v-list-item-title>
                                        <v-list-item-subtitle
                                            v-text="
                                                `${$t('Product no')}: ${
                                                    item.item_number
                                                }`
                                            "
                                        ></v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        class="mt-0 ml-2"
                        color="primary"
                        :loading="loading"
                        @click="download"
                        >Download</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api, defaultLanguageIso } from "~/config";

export default {
    data() {
        return {
            filter: {
                products: [],
                languages: [],
                fields: []
            },
            products: [],
            search: null,
            searchLoading: false,
            selectedLanguage: defaultLanguageIso,
            crumbs: [
                {
                    text: this.$t("Dashboard"),
                    disabled: false,
                    href: "/"
                },
                {
                    text: this.$t("Products"),
                    disabled: false,
                    href: "/products"
                },
                {
                    text: this.$t("Export"),
                    disabled: true,
                    href: "/products/export"
                }
            ],

            loading: false
        };
    },
    computed: {
        ...mapGetters({
            tenant: "auth/tenant"
        })
    },

    watch: {
        search(val) {
            if (!val || val.length < 3) return;

            this.fetchEntriesDebounced(val);
        }
    },

    methods: {
        filterProducts(item, queryText) {
            return (
                item.item_number
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1 ||
                item.name[this.selectedLanguage]
                    .toLocaleLowerCase()
                    .indexOf(queryText.toLocaleLowerCase()) > -1
            );
        },

        fetchEntriesDebounced(val) {
            // cancel pending call
            clearTimeout(this._timerId);

            // delay new call 500ms
            this._timerId = setTimeout(() => {
                this.products = [];

                this.searchLoading = true;

                axios
                    .get(
                        api.path("products.queryWithinTenant", {
                            term: val,
                            tenant: this.tenant
                        })
                    )
                    .then(r => r.data)
                    .then(products => {
                        this.products = products.data;
                    })
                    .finally(() => (this.searchLoading = false));
            }, 500);
        },

        async download() {
            let appendUrl = "";
            this.loading = true;

            let payload = {
                products: this.filter.products,
                tenant: this.tenant
            };

            if (payload) {
                if (payload.tenant !== undefined) {
                    appendUrl += "&tenant=" + payload.tenant;
                }

                if (
                    payload.products !== undefined &&
                    payload.products.length > 0
                ) {
                    appendUrl += "&products=" + payload.products.join(",");
                }
            }

            axios({
                url: api.path("products.printDataExport") + appendUrl,
                method: "GET",
                responseType: "blob"
            })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    );

                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", "files.zip");
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(() => {
                    this.$store.dispatch("ui/showSnackbar", {
                        text: this.$t("Es ist ein Fehler aufgetreten!"),
                        color: "error"
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>
.action-btn {
    width: 22px;
    height: 22px;
}
.flag-inactive {
    -webkit-filter: grayscale(1) brightness(2.4);
    filter: grayscale(1) brightness(2.4);
}

.flag-check {
    margin: 0 6px;
}
</style>
