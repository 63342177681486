<template>
    <div class="sidemenu" :style="styles">
        <v-list class="pt-0 pb-0 elevation-1" dense>
            <template v-for="(item, index) in items">
                <v-divider v-show="!!index" :key="index"></v-divider>
                <v-list-item
                    :key="item.title"
                    :to="item.to"
                    ripple
                    exact
                    @click="item.action ? item.action(item) : null"
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ $t(item.title) }}
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="item.icon">
                        <v-icon v-show="!item.progress" :color="item.color">
                            {{ item.icon }}
                        </v-icon>
                        <v-progress-circular
                            v-if="item.action"
                            v-show="item.progress"
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </v-list-item-action>
                </v-list-item>
            </template>
        </v-list>
    </div>
</template>

<script>
export default {
    props: {
        threshold: {
            type: [Number, String],
            required: true
        },
        offset: {
            type: [Number, String],
            required: true
        },
        items: {
            type: Array,
            required: true
        }
    },

    data: () => ({
        currentOffset: 0,
        position: "relative",
        top: 0
    }),

    computed: {
        styles() {
            return {
                position: this.position,
                top: `${parseInt(this.top)}px`
            };
        }
    },

    methods: {
        onScroll() {
            this.currentOffset =
                window.pageYOffset || document.documentElement.offsetTop;
            const shouldFloat = this.currentOffset >= this.threshold;
            this.position = shouldFloat ? "fixed" : "relative";
            this.top = shouldFloat ? this.offset : 0;
        }
    }
};
</script>
