<template>
    <div>
        <h2 class="mb-3 primary--text text-h5">{{ $t("Your Profile") }}</h2>
        <v-card>
            <v-card-text>
                <v-text-field
                    v-model="user.name"
                    :label="$t('Name')"
                    filled
                    readonly
                ></v-text-field>
                <v-text-field
                    v-model="user.email"
                    :label="$t('Email')"
                    filled
                    readonly
                ></v-text-field>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data: () => ({
        user: {
            name: null,
            email: null
        }
    }),

    computed: mapGetters({
        auth: "auth/user"
    }),

    mounted() {
        this.user = Object.assign(this.user, this.auth);
    }
};
</script>
