<template>
    <v-layout justify-end mx-5 mb-2>
        <v-flex sm11 xs12>
            <v-card :elevation="0">
                <v-card-title>
                    <h6 class="text-h6 font-weight-thin primary--text ">
                        {{ $t("Gesamtexport") }}
                    </h6>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="5">
                            <v-select
                                v-model="selectedLanguage"
                                :items="languages"
                                item-text="label"
                                item-value="iso"
                                :label="$t('Languages')"
                                class="mr-3 mt-3"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-row>
                        <v-btn
                            class="ml-4"
                            color="primary"
                            :loading="loading"
                            @click="download"
                            >Download</v-btn
                        >
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import {
api, availableLanguages, defaultLanguageIso,
mandatoryLanguages
} from "~/config";

export default {
    data() {
        return {
            columns: [
                {
                    key: "packaging_size_id",
                    label: "packaging_size_id"
                },
                {
                    key: "brand_id",
                    label: "brand_id"
                },
                {
                    key: "product_group_id",
                    label: "product_group_id"
                },
                {
                    key: "product_group_code_id",
                    label: "product_group_code_id"
                },
                {
                    key: "name",
                    label: "name"
                },
                {
                    key: "extended_name",
                    label: "extended_name"
                },
                {
                    key: "description_short",
                    label: "description_short"
                },
                {
                    key: "description_long",
                    label: "description_long"
                },
                {
                    key: "slug",
                    label: "slug"
                },
                {
                    key: "marketing_text",
                    label: "marketing_text"
                },
                {
                    key: "label_1_text",
                    label: "label_1_text"
                },
                {
                    key: "label_2_text",
                    label: "label_2_text"
                },
                {
                    key: "guiding_price",
                    label: "guiding_price"
                },
                {
                    key: "unit_price",
                    label: "unit_price"
                },
                {
                    key: "weight_gross",
                    label: "weight_gross"
                },
                {
                    key: "weight_net",
                    label: "weight_net"
                },
                {
                    key: "quantity_of_pallet",
                    label: "quantity_of_pallet"
                },
                {
                    key: "weight_of_pallet",
                    label: "weight_of_pallet"
                },
                {
                    key: "layers_per_pallet",
                    label: "layers_per_pallet"
                },
                {
                    key: "quantity_per_pallet_layer",
                    label: "quantity_per_pallet_layer"
                },
                {
                    key: "quantity_per_package",
                    label: "quantity_per_package"
                },
                {
                    key: "dimensions",
                    label: "dimensions"
                },
                {
                    key: "inventory",
                    label: "inventory"
                },
                {
                    key: "inventory_per_piece",
                    label: "inventory_per_piece"
                },
                {
                    key: "minimum_order_quantity",
                    label: "minimum_order_quantity"
                },
                {
                    key: "image",
                    label: "image"
                },
                {
                    key: "gtin",
                    label: "gtin"
                },
                {
                    key: "ean_for_repackaging",
                    label: "ean_for_repackaging"
                },
                {
                    key: "customs_tariff_number",
                    label: "customs_tariff_number"
                },
                {
                    key: "distribution_id",
                    label: "distribution_id"
                },
                {
                    key: "features_text",
                    label: "features_text"
                },
                {
                    key: "chemical_type_id",
                    label: "chemical_type_id"
                },
                {
                    key: "application_text",
                    label: "application_text"
                },
                {
                    key: "dosage_text",
                    label: "dosage_text"
                },
                {
                    key: "dosage_exact",
                    label: "dosage_exact"
                },
                {
                    key: "dosage_min",
                    label: "dosage_min"
                },
                {
                    key: "dosage_max",
                    label: "dosage_max"
                },
                {
                    key: "grain_size",
                    label: "grain_size"
                },
                {
                    key: "dosage_unit",
                    label: "dosage_unit"
                },
                {
                    key: "take_note",
                    label: "take_note"
                },
                {
                    key: "is_ec_fertilizer",
                    label: "is_ec_fertilizer"
                },
                {
                    key: "registry_numbers",
                    label: "registry_numbers"
                },
                {
                    key: "is_fibl_listed",
                    label: "is_fibl_listed"
                },
                {
                    key: "disposal_note_id",
                    label: "disposal_note_id"
                },
                {
                    key: "storage_note_id",
                    label: "storage_note_id"
                },
                {
                    key: "emergency_information_id",
                    label: "emergency_information_id"
                },
                {
                    key: "transport_regulations",
                    label: "transport_regulations"
                },
                {
                    key: "safety_data_sheet",
                    label: "safety_data_sheet"
                },
                {
                    key: "technical_data_sheet",
                    label: "technical_data_sheet"
                },
                {
                    key: "export_web",
                    label: "export_web"
                },
                {
                    key: "shoppable",
                    label: "shoppable"
                },
                {
                    key: "is_new",
                    label: "Is new"
                },
                {
                    key: "show_calculator",
                    label: "Show calculator"
                },
                {
                    key: "effect_duration_id",
                    label: "effect_duration_id"
                },
                {
                    key: "product_line_id",
                    label: "product_line_id"
                },
                {
                    key: "nutrients",
                    label: "nutrients"
                },
                {
                    key: "base_substances",
                    label: "base_substances"
                },
                {
                    key: "minor_components",
                    label: "minor_components"
                }
            ],
            selectedLanguage: "de",
            selectedTenants: [],
            crumbs: [
                {
                    text: this.$t("Dashboard"),
                    disabled: false,
                    href: "/"
                },
                {
                    text: this.$t("Products"),
                    disabled: false,
                    href: "/products"
                },
                {
                    text: this.$t("Export"),
                    disabled: true,
                    href: "/products/export"
                }
            ],
            search: "",
            loading: false,
            pagination: {
                rowsPerPage: -1
            },
            filter: [],
            dialog: false,
            languages: availableLanguages,
            defaultLanguageIso: defaultLanguageIso,
            mandatoryLanguages: mandatoryLanguages,
            headers: [
                {
                    text: this.$t("Item number"),
                    value: "item_number",
                    sortable: false
                },
                {
                    text: this.$t("Status web"),
                    value: "export_web",
                    sortable: false
                },
                {
                    text: this.$t("Ready translations"),
                    value: "ready_translations",
                    sortable: false
                },
                {
                    text: this.$t("Tenant"),
                    value: "tenant.name",
                    sortable: false
                },
                { text: this.$t("Name"), value: "name", sortable: false },
                {
                    text: this.$t("Action"),
                    value: "action",
                    align: "right",
                    sortable: false
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            products: "products/productList",
            exportProducts: "products/productExports",
            listTotalCount: "products/listTotalCount",
            productFilter: "ui/productFilter",
            tenants: "master-data/tenants",
            tenant: "auth/tenant"
        })
    },

    created() {
        this.getTenants();
    },

    methods: {
        ...mapActions({
            fetchExportList: "products/fetchExportList",
            getTenants: "master-data/getTenants",
            setStatus: "products/setStatus",
            setProductFilter: "ui/setProductFilter"
        }),

        download: async function() {
            let appendUrl = "";
            this.loading = true;

            let payload = {
                columns: this.selectedFruits,
                language: this.selectedLanguage,
                tenant: this.tenant
            };

            if (payload) {
                if (payload.columns !== undefined) {
                    appendUrl +=
                        "&fields[products]=" +
                        payload.columns.join(",") +
                        ",product_number,packaging_size_id";
                }

                if (payload.tenant !== undefined) {
                    appendUrl += "&tenant=" + payload.tenant;
                }

                if (payload.language !== undefined) {
                    appendUrl += "&language=" + payload.language;
                }
            }

            axios({
                url: api.path("products.export") + appendUrl,
                method: "GET",
                responseType: "blob"
            })
                .then(response => {
                    this.loading = false;
                    var fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    );

                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", "product_export.csv");
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(() => {
                    this.$store.dispatch("ui/showSnackbar", {
                        text: this.$t("Es ist ein Fehler aufgetreten!"),
                        color: "error"
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        editItem: function(item) {
            this.$router.push({
                name: "product-edit",
                params: { id: item.id }
            });
        },

        stopEditItem: function() {
            this.dialog = false;
        },

        updateProductFilter(value) {
            this.setProductFilter(value);
        }
    }
};
</script>

<style scoped>
.action-btn {
    width: 22px;
    height: 22px;
}
.flag-inactive {
    -webkit-filter: grayscale(1) brightness(2.4);
    filter: grayscale(1) brightness(2.4);
}

.flag-check {
    margin: 0 6px;
}
</style>
