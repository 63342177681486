var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',[_c('h3',{staticClass:"text-h5 font-weight-thin primary--text pl-4 pt-2"},[_vm._v(" "+_vm._s(_vm.$t("Attributes"))+" ")])]),_c('v-card-text',[_c('v-container',{attrs:{"py-0":"","px-2":"","grid-list-xs":"","fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[(
                        _vm.attributes.chemical_types &&
                            _vm.attributes.chemical_types.length > 0
                    )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.chemical_type,"items":_vm.attributes.chemical_types,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","disabled":_vm.readonly,"label":_vm.$t('Chemical type')},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event.id,
                                'chemical_type_id'
                            )}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(_vm._s(data.item.name[_vm.language]))]}}],null,false,1288279418)})],1):_vm._e(),(
                        _vm.attributes.seasons && _vm.attributes.seasons.length > 0
                    )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.seasons,"items":_vm.attributes.seasons,"item-value":"id","item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"label":_vm.$t('Seasons'),"disabled":_vm.readonly,"multiple":""},on:{"input":function ($event) {
                                _vm.$emit(
                                    'updateProduct',
                                    $event, // $event.map(a => a.id),
                                    'seasons'
                                );
                            }}})],1):_vm._e(),(
                        _vm.attributes.characteristics &&
                            _vm.attributes.characteristics.length > 0
                    )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.characteristics,"items":_vm.attributes.characteristics,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","disabled":_vm.readonly,"label":_vm.$t('Characteristics'),"multiple":""},on:{"input":function ($event) {
                                _vm.$emit(
                                    'updateProduct',
                                    $event, // $event.map(a => a.id),
                                    'characteristics'
                                );
                            }}})],1):_vm._e(),(
                        _vm.attributes.effect_durations &&
                            _vm.attributes.effect_durations.length > 0
                    )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.effect_duration,"items":_vm.attributes.effect_durations,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","disabled":_vm.readonly,"label":_vm.$t('Effect duration')},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event.id,
                                'effect_duration_id'
                            )}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(_vm._s(data.item.name[_vm.language]))]}}],null,false,1288279418)})],1):_vm._e(),(
                        _vm.attributes.plant_groups &&
                            _vm.attributes.plant_groups.length > 0
                    )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.plant_groups,"items":_vm.attributes.plant_groups,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","label":_vm.$t('Plant group'),"disabled":_vm.readonly,"multiple":""},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'plant_groups')}}})],1):_vm._e(),(_vm.attributes.plants && _vm.attributes.plants.length > 0)?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.plants,"items":_vm.attributes.plants,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","label":_vm.$t('Plants'),"multiple":"","disabled":_vm.readonly},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'plants')}}})],1):_vm._e(),(_vm.attributes.tasks && _vm.attributes.tasks.length > 0)?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.tasks,"items":_vm.attributes.tasks,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","label":_vm.$t('Tätigkeit / Produktseiten'),"multiple":"","disabled":_vm.readonly},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'tasks')}}})],1):_vm._e(),(
                        _vm.attributes.product_lines &&
                            _vm.attributes.product_lines.length > 0
                    )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.product_line,"items":_vm.attributes.product_lines,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","label":_vm.$t('Produktlinie'),"disabled":_vm.readonly},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event.id,
                                'product_line_id'
                            )}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(_vm._s(data.item.name[_vm.language]))]}}],null,false,1288279418)})],1):_vm._e(),(
                        _vm.attributes.application_sites &&
                            _vm.attributes.application_sites.length > 0
                    )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.application_sites,"items":_vm.attributes.application_sites,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","disabled":_vm.readonly,"label":_vm.$t('Anwendungsort'),"multiple":""},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event,
                                'application_sites'
                            )}}})],1):_vm._e(),(
                        _vm.attributes.application_scopes &&
                            _vm.attributes.application_scopes.length > 0
                    )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.application_scopes,"items":_vm.attributes.application_scopes,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","disabled":_vm.readonly,"label":_vm.$t('Application scopes'),"multiple":""},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event,
                                'application_scopes'
                            )}}})],1):_vm._e(),(
                        _vm.attributes.task_areas &&
                            _vm.attributes.task_areas.length > 0
                    )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.task_areas,"items":_vm.attributes.task_areas,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","disabled":_vm.readonly,"label":_vm.$t('Tätigkeitsbereich'),"multiple":""},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'task_areas')}}})],1):_vm._e(),(
                        _vm.attributes.purposes &&
                            _vm.attributes.purposes.length > 0
                    )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.purposes,"items":_vm.attributes.purposes,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","disabled":_vm.readonly,"label":_vm.$t('Purposes'),"multiple":""},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'purposes')}}})],1):_vm._e(),(
                        _vm.attributes.hazard_statements &&
                            _vm.attributes.hazard_statements.length > 0
                    )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.hazard_statements,"items":_vm.attributes.hazard_statements,"item-text":"code","item-value":"id","label":_vm.$t('Hazard statements'),"small-chips":"","disabled":_vm.readonly,"multiple":""},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event,
                                'hazard_statements'
                            )}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" ["+_vm._s(data.item.code)+"]: "+_vm._s(data.item.statement[_vm.language])+" ")]}}],null,false,1515001652)})],1):_vm._e(),(
                        _vm.attributes.precautionary_statements &&
                            _vm.attributes.precautionary_statements.length > 0
                    )?_c('v-flex',{attrs:{"xs12":"","sm12":"","md6":""}},[_c('v-combobox',{attrs:{"value":_vm.product.precautionary_statements,"items":_vm.attributes.precautionary_statements,"item-text":"code","item-value":"id","label":_vm.$t('Precautionary statements'),"small-chips":"","disabled":_vm.readonly,"multiple":""},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event,
                                'precautionary_statements'
                            )}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" ["+_vm._s(data.item.code)+"]: "+_vm._s(data.item.statement[_vm.language])+" ")]}}],null,false,1515001652)})],1):_vm._e(),_c('v-flex',{attrs:{"xs12":"","sm6":"","md6":""}},[_c('v-text-field',{attrs:{"value":_vm.product.grain_size,"label":_vm.$t('Grain size'),"disabled":_vm.readonly,"hint":"z. B.: '1.5-3 mm'"},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'grain_size')}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","dark":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v("z. B.: \"1.5-3 mm\"")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }