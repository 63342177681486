var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',[_c('h3',{staticClass:"text-h5 font-weight-thin primary--text pl-4 pt-2"},[_vm._v(" "+_vm._s(_vm.$t("Texts"))+" ")])]),_c('v-card-text',[_c('v-container',{attrs:{"py-0":"","px-2":"","grid-list-xs":"","fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('h4',{staticClass:"text-h5 font-weight-thin primary--text"},[_vm._v(" "+_vm._s(_vm.$t("Descriptions"))+" ")])]),_c('v-flex',{attrs:{"xs12":""}},[_c('translateable',{attrs:{"value":_vm.product.description_short,"default-language":_vm.language,"multiline":true,"label":_vm.$t('Description short'),"maxlength":128,"readonly":_vm.readonly,"counter":128},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event,
                                'description_short'
                            )}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","light":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( "Produktnamen und Anwendung (wie vorne auf Packung)" )))])])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('translateable',{attrs:{"value":_vm.product.features_text,"default-language":_vm.language,"multiline":true,"readonly":_vm.readonly,"label":_vm.$t('Features')},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'features_text')}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","light":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( "max. 5 Vorteile eines Produktes (Aufzählung)" )))])])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('translateable',{attrs:{"value":_vm.product.description_long,"default-language":_vm.language,"multiline":true,"readonly":_vm.readonly,"label":_vm.$t('Description Text')},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event,
                                'description_long'
                            )}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","light":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( "Einführungs-Produkttext (erster Abschnitt von Verpackungstext)" )))])])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('h4',{staticClass:"text-h5 font-weight-thin primary--text"},[_vm._v(" "+_vm._s(_vm.$t("Features and dosage"))+" ")])]),_c('v-flex',{attrs:{"xs12":""}},[_c('translateable',{attrs:{"value":_vm.product.application_text,"default-language":_vm.language,"multiline":true,"readonly":_vm.readonly,"label":_vm.$t('Application')},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event,
                                'application_text'
                            )}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","light":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("Anwendungsempfehlung")))])])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('translateable',{attrs:{"value":_vm.product.dosage_text,"default-language":_vm.language,"multiline":true,"label":_vm.$t('Dosage'),"readonly":_vm.readonly},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'dosage_text')}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","light":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( "Dosierungsempfehlung / Düngerplan detailliert" )))])])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{staticClass:"mx-5",attrs:{"value":_vm.product.dosage_exact,"type":"number","step":"0.01","disabled":_vm.readonly,"label":_vm.$t('Dosage simple')},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'dosage_exact')}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","dark":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v("Exakte Dosierung")])]),_c('template',{slot:"append"},[_vm._v(_vm._s(_vm.$t(_vm.product.dosage_unit)))])],2)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-select',{staticClass:"mx-5",attrs:{"value":_vm.product.dosage_unit,"items":_vm.dosageUnitItems,"label":_vm.$t('Dosage unit'),"disabled":_vm.readonly,"clearable":""},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'dosage_unit')}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(_vm._s(_vm.$t(data.item)))]}},{key:"item",fn:function(data){return [_vm._v(_vm._s(_vm.$t(data.item)))]}}])},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","dark":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v("Einheit der Dosierung für Dosierungsangaben")])])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{staticClass:"mx-5",attrs:{"value":_vm.product.dosage_min,"type":"number","step":"0.01","disabled":_vm.readonly,"label":_vm.$t('Dosage min')},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'dosage_min')}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","dark":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v("Dosierung minimum")])]),_c('template',{slot:"append"},[_vm._v(_vm._s(_vm.$t(_vm.product.dosage_unit)))])],2)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{staticClass:"mx-5",attrs:{"value":_vm.product.dosage_max,"type":"number","step":"0.01","label":_vm.$t('Dosage max'),"disabled":_vm.readonly},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'dosage_max')}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","dark":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v("Dosierung maximum")])]),_c('template',{slot:"append"},[_vm._v(_vm._s(_vm.$t(_vm.product.dosage_unit)))])],2)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('h4',{staticClass:"text-h5 font-weight-thin primary--text"},[_vm._v(" "+_vm._s(_vm.$t("Hints"))+" ")])]),_c('v-flex',{attrs:{"xs12":""}},[_c('translateable',{attrs:{"value":_vm.product.take_note,"default-language":_vm.language,"multiline":true,"readonly":_vm.readonly,"label":_vm.$t('Zu beachten')},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'take_note')}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","light":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( "Produktespezifische Informationen nebst der Gebrauchsanleitung" )))])])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-select',{staticClass:"mx-5",attrs:{"value":_vm.product.storage_notes,"items":_vm.attributes.storage_notes,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","label":_vm.$t('Storage notes'),"small-chips":"","disabled":_vm.readonly,"return-object":""},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event.id,
                                'storage_note_id'
                            )}}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-select',{staticClass:"mx-5",attrs:{"value":_vm.product.disposal_notes,"items":_vm.attributes.disposal_notes,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","return-object":"","disabled":_vm.readonly,"label":_vm.$t('Disposal notes'),"small-chips":""},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event.id,
                                'disposal_note_id'
                            )}}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-select',{staticClass:"mx-5",attrs:{"value":_vm.product.emergency_information,"items":_vm.attributes.emergency_information,"item-text":function (item) { return item.name[_vm.language] ||
                                item.name[_vm.fallbackLanguage]; },"item-value":"id","disabled":_vm.readonly,"return-object":"","label":_vm.$t('Emergency information'),"small-chips":""},on:{"input":function($event){return _vm.$emit(
                                'updateProduct',
                                $event.id,
                                'emergency_information_id'
                            )}}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('h4',{staticClass:"text-h5 font-weight-thin primary--text"},[_vm._v(" "+_vm._s(_vm.$t("Website"))+" ")])]),_c('v-flex',{attrs:{"xs12":""}},[_c('translateable',{attrs:{"value":_vm.product.special_text,"default-language":_vm.language,"multiline":true,"readonly":_vm.readonly,"label":_vm.$t('Spezialtext')},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'special_text')}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","light":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("Spezialtext")))])])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('translateable',{attrs:{"value":_vm.product.marketing_text,"default-language":_vm.language,"multiline":true,"label":_vm.$t('Marketingtext'),"maxlength":1700,"readonly":_vm.readonly,"counter":1700},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'marketing_text')}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","light":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("Produktetext, max. 1700 Zeichen")))])])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('translateable',{attrs:{"value":_vm.product.label_1_text,"default-language":_vm.language,"multiline":false,"label":_vm.$t('Labeltext 1'),"readonly":_vm.readonly,"maxlength":35,"counter":35},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'label_1_text')}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","light":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( "Anschreibung des Warenregals mit Produktenamen" )))])])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('translateable',{attrs:{"value":_vm.product.label_2_text,"default-language":_vm.language,"multiline":false,"label":_vm.$t('Labeltext 2'),"maxlength":35,"readonly":_vm.readonly,"counter":35},on:{"input":function($event){return _vm.$emit('updateProduct', $event, 'label_2_text')}}},[_c('v-tooltip',{attrs:{"slot":"prepend","right":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"yellow darken-1","light":""}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( "Anschreibung des Warenregals mit wichtigster Anwendung" )))])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }