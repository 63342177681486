var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"justify-end":"","mx-5":"","mb-2":""}},[_c('v-flex',{attrs:{"sm11":"","xs12":""}},[_c('v-card',{attrs:{"elevation":0,"height":"100%"}},[_c('v-card-title',[_c('h6',{staticClass:"text-h6 font-weight-thin primary--text"},[_vm._v(" "+_vm._s(_vm.$t("Individueller Export"))+" ")])]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('v-select',{attrs:{"items":_vm.languages,"attach":"","chips":"","label":_vm.$t('Languages'),"multiple":"","item-value":"iso","item-text":"label"},model:{value:(_vm.filter.languages),callback:function ($$v) {_vm.$set(_vm.filter, "languages", $$v)},expression:"filter.languages"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('v-autocomplete',{attrs:{"items":_vm.products,"attach":"","chips":"","label":_vm.$t('Products'),"multiple":"","search-input":_vm.search,"item-value":"id","filter":_vm.filterProducts,"loading":_vm.searchLoading,"clearable":"","deletable-chips":"","cache-items":"","placeholder":_vm.$t(
                                    'Mit der Eingabe beginnen um zu suchen ...'
                                ),"item-text":"item_number"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"select":function($event){_vm.search = ''}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                                var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name[_vm.selectedLanguage])}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                                            ((_vm.$t('Product no')) + ": " + (item.item_number))
                                        )}})],1)]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t( "Start typing to search products." ))+" ")])],1)]},proxy:true}]),model:{value:(_vm.filter.products),callback:function ($$v) {_vm.$set(_vm.filter, "products", $$v)},expression:"filter.products"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',{staticClass:"px-0"},[_c('v-expansion-panel-header',{staticClass:"px-0",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var open = ref.open;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$t("Fields"))+" ")]),_c('v-spacer'),_c('v-col',{staticClass:"text--secondary",attrs:{"cols":"2"}},[_c('v-fade-transition',{attrs:{"leave-absolute":""}},[(!open)?_c('span',[_vm._v(" "+_vm._s(_vm.filter.fields .length)+" "+_vm._s(_vm.$t( "fields selected" ))+" ")]):_vm._e()])],1)],1)]}}])}),_c('v-expansion-panel-content',{staticClass:"px-0"},[_c('v-row',[_c('v-col',{staticClass:"px-0"},[_c('v-card-text',{staticClass:"px-0"},[_c('v-treeview',{attrs:{"items":_vm.items,"selected-color":"primary","open-on-click":"","selectable":"","return-object":false,"expand-icon":"mdi-chevron-down","item-disabled":"locked","on-icon":"mdi-checkbox-marked","off-icon":"mdi-checkbox-blank-outline","indeterminate-icon":"mdi-minus-box"},model:{value:(_vm.filter.fields),callback:function ($$v) {_vm.$set(_vm.filter, "fields", $$v)},expression:"filter.fields"}})],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card-text',[(_vm.tree.length === 0)?_c('div',{key:"title",staticClass:"text-h6 font-weight-light grey--text pa-4 text-center"},[_vm._v(" "+_vm._s(_vm.$t("Selection"))+" ")]):_vm._e(),_c('v-scroll-x-transition',{attrs:{"group":"","hide-on-leave":""}},_vm._l((_vm.filter.fields),function(selection,i){return _c('v-chip',{key:i,staticClass:"ma-1",attrs:{"color":"grey","dark":"","small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-format-columns ")]),_vm._v(" "+_vm._s(selection)+" ")],1)}),1)],1)],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"black-divider",attrs:{"light":""}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mt-5 ml-2",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.download}},[_vm._v("Download")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }