<template>
    <v-container fluid grid-list-xl>
        <v-layout justify-center wrap>
            <v-flex lg12 sm12 xs12>
                <v-breadcrumbs class="pl-1" :items="crumbs">
                    <v-icon slot="divider">chevron_right</v-icon>
                </v-breadcrumbs>

                <v-card-title class="pl-0">
                    <h3
                        class="text-h5 font-weight-thin primary--text pl-4 pt-2"
                    >
                        {{ $t("Dashboard") }}
                    </h3>
                </v-card-title>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            sidemenuItems: [],
            crumbs: [
                {
                    text: this.$t("Start"),
                    disabled: false,
                    href: "/"
                },
                {
                    text: this.$t("Dashboard"),
                    disabled: true,
                    href: "/"
                }
            ]
        };
    },

    mounted() {
        this.navigation();
    },

    methods: {
        navigation() {
            this.sidemenuItems = [
                {
                    title: "View",
                    icon: "visibility",
                    to: { name: "profile" }
                },
                {
                    title: "Edit",
                    icon: "edit",
                    to: { name: "profile-edit" }
                }
            ];
        }
    }
};
</script>

>
