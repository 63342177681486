<template>
    <v-container fluid grid-list-xl>
        <v-layout justify-center wrap fill-height>
            <v-flex lg12 sm12 xs12>
                <v-breadcrumbs class="pl-1" :items="crumbs">
                    <v-icon slot="divider">chevron_right</v-icon>
                </v-breadcrumbs>
                <h3 class="text-h5 font-weight-thin primary--text pt-2 mb-5">
                    {{ $t("Export products") }}
                </h3>

                <v-card height="100%">
                    <v-tabs vertical color="primary" mt-5 style="height:100%">
                        <v-tab>
                            {{ $t("Gesamt") }}
                        </v-tab>
                        <v-tab>
                            {{ $t("Individuell") }}
                        </v-tab>
                        <v-tab>
                            {{ $t("Druckdaten") }}
                        </v-tab>
                        <v-tab>
                            {{ $t("Datenblatt") }}
                        </v-tab>

                        <v-tab-item>
                            <full-export />
                        </v-tab-item>
                        <v-tab-item>
                            <custom-export />
                        </v-tab-item>
                        <v-tab-item>
                            <print-data-export />
                        </v-tab-item>
                        <v-tab-item>
                            <dataSheetExport />
                        </v-tab-item>
                    </v-tabs>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        fullExport: require("$comp/product/export/ProductFullExport").default,
        dataSheetExport: require("$comp/product/export/DataSheetExport")
            .default,
        printDataExport: require("$comp/product/export/PrintDataExport")
            .default,
        customExport: require("$comp/product/export/ProductCustomExport")
            .default
    },
    data() {
        return {
            crumbs: [
                {
                    text: this.$t("Dashboard"),
                    disabled: false,
                    href: "/"
                },
                {
                    text: this.$t("Products"),
                    disabled: false,
                    href: "/products"
                },
                {
                    text: this.$t("Export"),
                    disabled: true,
                    href: "/products/export"
                }
            ]
        };
    },
    computed: {
        ...mapGetters({})
    },

    created() {},

    methods: {
        ...mapActions({})
    }
};
</script>
