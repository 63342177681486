<template>
    <v-row justify="center">
        <v-dialog :value="open" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="text-h6">{{ $t("Version Information") }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="3">
                                <span class="font-weight-bold"
                                    >Erstellt am:</span
                                >
                            </v-col>
                            <v-col cols="9">
                                {{
                                    product.version &&
                                        moment(
                                            product.version.created_at
                                        ).format("DD.MM.YYYY - HH:mm ")
                                }}
                                Uhr
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="3">
                                <span class="font-weight-bold"
                                    >Erstellt von:</span
                                >
                            </v-col>
                            <v-col cols="9">
                                {{
                                    product.version &&
                                        product.version.created_by.name
                                }}
                                ({{
                                    product.version &&
                                        product.version.created_by.email
                                }})
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3">
                                <span class="font-weight-bold">Kommentar:</span>
                            </v-col>
                            <v-col cols="9">
                                <v-textarea
                                    name="reason"
                                    :value="
                                        product.version &&
                                            product.version.reason
                                    "
                                    :disabled="readonly"
                                    hint="Grund / Kommentar"
                                    @input="changeReason"
                                ></v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12"> </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                        {{ $t("Close") }}
                    </v-btn>
                    <v-btn
                        v-if="!readonly"
                        color="blue darken-1"
                        text
                        :disabled="loading"
                        @click="storeMetaData"
                    >
                        {{ $t("Save") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { api } from "~/config";
export default {
    props: {
        open: {
            type: Boolean,
            required: true
        },
        product: {
            type: Object,
            default: () => {
                return {
                    id: null,
                    version: {
                        reason: null,
                        created_by: {
                            name: null,
                            email: null
                        },
                        created_at: null,
                        name: null
                    }
                };
            }
        },
        readonly: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            moment: moment,
            reason: null,
            loading: false
        };
    },

    methods: {
        changeReason(reason) {
            this.reason = reason;
            const version = { ...this.product.version };
            version.reason = reason;
            this.$emit("updateProduct", version, "version");
        },
        reset() {
            this.reason = null;
        },
        close() {
            this.reset();
            this.$emit("close");
        },
        async storeMetaData() {
            this.loading = true;
            await axios
                .put(api.path("products.metadata", { id: this.product.id }), {
                    reason: this.product.version.reason
                })
                .then(() => {
                    this.$store.dispatch("ui/showSnackbar", {
                        text: this.$t("Erfolgreich gespeichert"),
                        color: "success"
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
