<template>
    <v-container fluid grid-list-xl>
        <v-layout justify-center wrap>
            <v-flex lg12 sm12 xs12>
                <v-breadcrumbs class="pl-1" :items="crumbs">
                    <v-icon slot="divider">chevron_right</v-icon>
                </v-breadcrumbs>
                <v-card>
                    <v-card-title class="pl-0">
                        <h3
                            class="text-h5 font-weight-thin primary--text pl-4 pt-2"
                        >
                            {{ $t("Sync Products") }}
                        </h3>

                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>

                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table>
                            <template #default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Mandant</th>
                                        <th class="text-left">
                                            Letzte Synchronisierung
                                        </th>
                                        <th class="text-left">
                                            Anzahl Produkte aktualisiert
                                        </th>
                                        <th class="text-left">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in stats" :key="item.name">
                                        <td>{{ item.tenant }}</td>
                                        <td>{{ item.last }}</td>
                                        <td>{{ item.count }}</td>
                                        <td>
                                            {{
                                                item.running === true
                                                    ? "Running..."
                                                    : "Finished."
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <!--
                        <v-divider class="mt-5"></v-divider>
                        <v-select
                            ref="inlTenants"
                            v-model="selectedTenants"
                            :items="tenants"
                            :item-text="'name.de'"
                            item-value="abbreviation"
                            chips
                            :label="$t('Tenants')"
                            multiple
                        ></v-select> 
                        -->
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            :loading="loading"
                            color="primary"
                            @click="triggerSync"
                            >Ausführen</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { api } from "~/config";
import axios from "axios";
import Form from "~/mixins/form";

export default {
    mixins: [Form],
    data() {
        return {
            selectedTenants: [],
            crumbs: [
                {
                    text: this.$t("Dashboard"),
                    disabled: false,
                    href: "/"
                },
                {
                    text: this.$t("Products"),
                    disabled: false,
                    href: "/products"
                },
                {
                    text: this.$t("Sync"),
                    disabled: true,
                    href: "/products/sync"
                }
            ],
            search: "",
            loading: false,
            stats: [],
            pagination: {
                rowsPerPage: -1
            },
            filter: [],
            dialog: false
        };
    },
    computed: {
        ...mapGetters({
            products: "products/productList",
            exportProducts: "products/productExports",
            listTotalCount: "products/listTotalCount",
            productFilter: "ui/productFilter",
            tenants: "master-data/tenants",
            authTenants: "auth/tenants",
            tenant: "auth/tenant"
        })
    },

    created() {
        this.getTenants();
        this.getStats();
    },

    methods: {
        ...mapActions({
            fetchExportList: "products/fetchExportList",
            getTenants: "master-data/getTenants",
            setStatus: "products/setStatus",
            setProductFilter: "ui/setProductFilter"
        }),

        triggerSync: async function() {
            this.loading = true;

            let payload = {
                tenants: [this.tenant]
            };

            axios
                .post(api.path("products.syncProducts"), payload)
                .then(({ data }) => {
                    this.$store.dispatch("ui/showSnackbar", {
                        text: this.$t("Sync as been triggered."),
                        color: "success"
                    });
                    this.stats = data;
                })
                .catch(err => {
                    this.handleErrors(err.response.data.errors);
                })
                .then(() => {
                    this.loading = false;
                });
        },

        getStats: async function() {
            this.loading = true;
            axios
                .get(api.path("products.syncStatus", { tenant: this.tenant }))
                .then(({ data }) => {
                    this.stats = data;
                })
                .catch(err => {
                    this.handleErrors(err.response.data.errors);
                })
                .then(() => {
                    this.loading = false;
                });
        },

        editItem: function(item) {
            this.$router.push({
                name: "product-edit",
                params: { id: item.id }
            });
        },

        stopEditItem: function() {
            this.dialog = false;
        },

        toggleStatus(item) {
            let payload = { item: item, status: true };
            if (item.export_web === true) {
                payload = { item: item, status: false };
            }
            this.setStatus(payload);
        },
        toggle() {
            this.$nextTick(() => {
                if (this.likesAllFruit) {
                    this.selectedFruits = [];
                } else {
                    this.selectedFruits = this.columns.map(c => c.key);
                }
            });
        }
    }
};
</script>

<style scoped>
.action-btn {
    width: 22px;
    height: 22px;
}
.flag-inactive {
    -webkit-filter: grayscale(1) brightness(2.4);
    filter: grayscale(1) brightness(2.4);
}

.flag-check {
    margin: 0 6px;
}
</style>
