<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
        <v-text-field
            v-model="form.email"
            :label="labels.email"
            type="email"
            :error-messages="errors.email"
            :rules="[rules.required('email')]"
            :disabled="loading"
        ></v-text-field>

        <v-layout class="mt-2">
            <v-spacer></v-spacer>

            <v-btn
                text
                :disabled="loading"
                :to="{ name: 'login', query: { email: form.email } }"
                color="grey darken-2"
                >{{ $t("Back to login") }}</v-btn
            >

            <v-btn
                type="submit"
                :loading="loading"
                :disabled="loading || !valid"
                color="primary"
                >{{ $t("Get password") }}</v-btn
            >
        </v-layout>
    </v-form>
</template>

<script>
import axios from "axios";
import { api } from "~/config";
import Form from "~/mixins/form";

export default {
    mixins: [Form],

    data: () => ({
        form: {
            email: null
        }
    }),

    created() {
        this.form.email = this.$route.query.email || null;
    },

    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                axios
                    .post(api.path("password.forgot"), this.form)
                    .then(() => {
                        this.$store.dispatch("ui/showSnackbar", {
                            text: this.$t(
                                "An email with password reset instructions has been sent to your email address."
                            ),
                            color: "info",
                            top: true
                        });
                        this.$emit("success");
                    })
                    .catch(err => {
                        this.handleErrors(err.response.data.errors);
                    })
                    .then(() => {
                        this.loading = false;
                    });
            }
        }
    }
};
</script>
