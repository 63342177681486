import formatter from "~/helpers/formatter";

export default {
    data: () => ({
        loading: false,
        valid: true,
        labels: {},
        form: {},
        rules: {},
        errors: {}
    }),

    created() {
        for (let key in this.form) {
            if (this.form[key] !== null && typeof this.form[key] === "object") {
                for (let i in this.form[key]) {
                    let key2 = key + "." + i;
                    this.errors[key2] = [];
                    if (!this.labels[key2]) {
                        this.labels[key2] = formatter.titlecase(i);
                    }
                }
            } else {
                this.errors[key] = [];
                if (!this.labels[key]) {
                    this.labels[key] = formatter.titlecase(key);
                }
            }
        }

        this.rules.required = field => v =>
            !!v ||
            this.$t("The form field is required", {
                field: this.labels && this.labels[field]
            });
    },

    methods: {
        handleErrors(errors) {
            if (errors) {
                this.setErrors(errors);
            } else {
                this.clearErrors();
            }
        },

        setErrors(errors) {
            for (let key in this.errors) {
                this.errors[key] = errors[key] || [];
            }
        },

        clearErrors() {
            for (let key in this.errors) {
                this.errors[key] = [];
            }
        }
    }
};
