import axios from "axios";
import { api, mediavaletAuthApi } from "~/config";
import router from "~/router/index";
import store from "~/store/index";

axios.interceptors.request.use(
    config => {
        // Add some headers
        config.headers["X-Requested-With"] = "XMLHttpRequest";
        config.headers["Access-Control-Allow-Origin"] = "*";

        // Default (internal) request - use the middleware api auth token
        let token = store.getters["auth/token"];

        /*
  // But if we request to mediavalet - use the mediavalet token
  if (config.url.indexOf(mediavaletApi.base) >= 0) {
    token = store.getters['mediavalet/token']
    if (!token) {
      store.dispatch('mediavalet/getToken').then(() => {
        token = store.getters['mediavalet/token']
      })
    }
  }

  // Add some extra headers for mediavalet requests
  if (config.url.indexOf(mediavaletAuthApi.base) >= 0 && config.url.indexOf(mediavaletApi.base) >= 0) {
    config.headers['Ocp-Apim-Subscription-Key'] = '034cd0c5fae443e9a28b4c45b36d369d'
  }*/

        // Add the token - only for middleware api request
        if (config.url.indexOf(api.base) === 0 && token) {
            config.headers["Authorization"] = "Bearer " + token;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.defaults.trailingSlash = true;

//remove trailing slash on url
axios.interceptors.request.use(config => {
    if (config.url[config.url.length - 1] === "/") {
        config.url = config.url.slice(0, -1);
    }
    return config;
});

axios.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const { config } = error;
        if (store.getters["auth/token"]) {
            // TODO: Find more reliable way to determine when Token state
            if (
                error.response.status === 401 &&
                error.response.data.message === "Token has expired"
            ) {
                const { data } = await axios.post(api.path("login.refresh"));
                store.dispatch("auth/saveToken", data);
                return axios.request(error.config);
            }

            if (
                error.response.status === 401 ||
                (error.response.status === 500 &&
                    (error.resopnse.data.message ===
                        "Token has expired and can no longer be refreshed" ||
                        error.resopnse.data.message ===
                            "The token has been blacklisted"))
            ) {
                if (config.url.indexOf(mediavaletAuthApi.url)) {
                    store.dispatch("mediavalet/destroy");
                    store.dispatch("mediavalet/getToken");
                } else {
                    store.dispatch("auth/destroy");
                    router.push({
                        name: "login"
                    });
                }
            }
        }

        if (typeof error.response.data.error.detail === "object") {
            let message = "";
            Object.keys(error.response.data.error.detail).forEach(function(
                key
            ) {
                if (Array.isArray(error.response.data.error.detail[key])) {
                    error.response.data.error.detail[key].forEach(el => {
                        message += el + "";
                    });
                }
            });
            store.dispatch("ui/showSnackbar", {
                text: message.replace(/\n/g, "<br />"),
                color: "error"
            });
        } else {
            store.dispatch("ui/showSnackbar", {
                text: error.response.data.error.detail.replace(/\n/g, "<br />"),
                color: "error"
            });
        }

        return Promise.reject(error);
    }
);
