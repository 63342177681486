<template>
    <v-card class="elevation-0">
        <v-card-title>
            <h3 class="text-h5 font-weight-thin primary--text ml-2">
                {{ $t("Labels") }}
            </h3>
        </v-card-title>
        <v-card-text>
            <v-container py-0 px-2 grid-list-xs fluid>
                <v-layout wrap>
                    <v-flex xs12 sm12 md6>
                        <v-checkbox
                            :input-value="product.is_ec_fertilizer"
                            color="primary"
                            :label="$t('EC-fertilizer')"
                            :disabled="readonly"
                            @change="
                                $emit(
                                    'updateProduct',
                                    $event,
                                    'is_ec_fertilizer'
                                )
                            "
                        ></v-checkbox>
                        <v-checkbox
                            :input-value="product.is_fibl_listed"
                            color="primary"
                            :disabled="readonly"
                            :label="$t('FiBL listed')"
                            @change="
                                $emit('updateProduct', $event, 'is_fibl_listed')
                            "
                        ></v-checkbox>
                    </v-flex>
                    <v-flex xs12>
                        <h5 class="primary--text text-h5 underline">
                            {{ $t("Symbols") }}
                        </h5>
                    </v-flex>
                    <v-flex xs12 sm12 md6>
                        <v-combobox
                            :value="product.organic_labels"
                            :items="attributes.organic_labels"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            :disabled="readonly"
                            item-value="id"
                            :label="$t('Organic labels')"
                            multiple
                            @input="
                                $emit('updateProduct', $event, 'organic_labels')
                            "
                        ></v-combobox>
                    </v-flex>
                    <v-flex xs12 sm12 md6>
                        <v-combobox
                            :value="product.hazard_symbols"
                            :items="attributes.hazard_symbols"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            :disabled="readonly"
                            item-value="id"
                            :label="$t('Hazard symbols')"
                            multiple
                            @input="
                                $emit('updateProduct', $event, 'hazard_symbols')
                            "
                        ></v-combobox>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    props: {
        product: {
            type: Object,
            required: true
        },
        dialog: {
            type: Boolean,
            default: false
        },
        language: {
            type: String,
            required: true
        },
        fallbackLanguage: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            attributes: "master-data/attributes"
        })
    }
};
</script>
