<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
        left
        transition="slide-x-transition"
    >
        <template #activator="{ on }">
            <v-btn :disabled="readonly" text icon color="primary" v-on="on">
                <v-icon :small="small">cached</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title class="primary--text font-weight-bold">{{
                $t("Kopieren")
            }}</v-card-title>
            <v-card-text>
                <div v-if="withLanguageCopy" class="mb-5">
                    <p>Von anderer Sprache</p>
                    <v-select
                        v-model="copyFromLanguage"
                        :items="selectableLanguages"
                        :label="$t('Sprache')"
                        item-text="label"
                        :search-input.sync="search"
                        item-value="iso"
                        class="mr-3"
                    ></v-select>
                    <v-divider />
                </div>
                <p>Von ähnlichen Produkt</p>
                <v-autocomplete
                    v-model="copyFrom"
                    :items="items"
                    item-text="item_number"
                    :loading="searchLoading"
                    item-value="id"
                    :search-input.sync="search"
                    :label="$t('Item number')"
                    :placeholder="
                        $t('Mit der Eingabe beginnen um zu suchen ...')
                    "
                    persistent-hint
                >
                    <template #no-data>
                        <v-list-item>
                            <v-list-item-title>
                                {{ $t("Start typing to search products.") }}
                            </v-list-item-title>
                        </v-list-item>
                    </template>
                    <template #item="data">
                        <v-list-item-content>
                            <v-list-item-title>{{
                                data.item.item_number
                            }}</v-list-item-title>
                            <v-list-item-subtitle>
                                <span class="primary--text font-weight-bold">
                                    {{ tenantIdToString(data.item.tenant_id) }}
                                </span>
                                | {{ data.item.name[selectedLanguage] }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    text
                    outlined
                    light
                    color="primary"
                    @click.stop="copy()"
                    >{{ $t("Copy") }}</v-btn
                >
                <v-btn
                    text
                    outlined
                    color="accent"
                    dark
                    @click.stop="menu = false"
                    >{{ $t("Cancel") }}</v-btn
                >
                <v-spacer />
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { api, availableLanguages, tenants } from "~/config";

export default {
    props: {
        fields: {
            type: Array,
            default: () => {
                return [];
            }
        },

        small: {
            type: Boolean,
            default: false
        },

        selectedLanguage: {
            type: [Boolean, String],
            default: false
        },

        withLanguageCopy: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            menu: false,
            copyFrom: "",
            languages: availableLanguages,
            copyFromLanguage: "",
            items: [],
            tenants: tenants,
            search: null,
            searchLoading: false
        };
    },

    computed: {
        ...mapGetters({
            loading: "ui/loading",
            tenant: "auth/tenant"
        }),

        selectableLanguages() {
            return this.languages.filter(obj => {
                return obj.iso !== this.selectedLanguage;
            });
        }
    },

    watch: {
        menu() {
            this.items = [];
        },
        search(val) {
            if (!val || val.length < 3) return;

            this.fetchEntriesDebounced(val);
        }
    },

    methods: {
        ...mapActions({
            setLoading: "ui/setLoading",
            copyProductToEdit: "products/copyProductToEdit"
        }),

        fetchEntriesDebounced(val) {
            // cancel pending call
            clearTimeout(this._timerId);

            // delay new call 500ms
            this._timerId = setTimeout(() => {
                this.items = [];

                this.searchLoading = true;

                axios
                    .get(api.path("products.query", { term: val }))
                    .then(r => r.data)
                    .then(products => {
                        this.items = products.data;
                    })
                    .finally(() => (this.searchLoading = false));
            }, 500);
        },

        copy() {
            if (this.copyFrom == "" && this.copyFromLanguage == "") {
                return;
            }
            this.setLoading(true);
            this.copyProductToEdit({
                id: this.copyFrom,
                fields: this.fields,
                fromLanguage: this.copyFromLanguage,
                toLanguage: this.selectedLanguage,
                tenant: this.tenant
            })
                .then(() => {
                    this.$store.dispatch("ui/showSnackbar", {
                        text: this.$t("Erfolgreich kopiert"),
                        color: "success"
                    });
                })
                .finally(() => {
                    this.setLoading(false);
                    this.$emit("synced");
                });
        },

        tenantIdToString(id) {
            let tenant = this.tenants.filter(obj => {
                return obj.id === id;
            });

            if (tenant.length > 0) {
                return this.$t("tenant_" + tenant[0].abbr);
            } else {
                return "";
            }
        }
    }
};
</script>
