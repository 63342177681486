<template>
    <v-card class="elevation-0">
        <v-card-title>
            <h3 class="text-h5 font-weight-thin primary--text pl-4 pt-2">
                {{ $t("Prices") }}
                <navisionIcon />
            </h3>
        </v-card-title>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="product.prices"
                :items-per-page="15"
                class="elevation-0"
                :no-data-text="$t('No prices for this product available')"
            >
                <template #item.vat_included="{ item }">
                    <v-chip
                        x-small
                        :color="
                            item.vat_included === true ? 'success' : 'warning'
                        "
                        dark
                    >
                        {{ item.vat_included === true ? $t("Yes") : $t("No") }}
                    </v-chip>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    components: {
        navisionIcon: require("$comp/ui/NavisionIcon").default
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        dialog: {
            type: Boolean,
            default: false
        },
        fallbackLanguage: {
            type: String,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            headers: [
                {
                    text: this.$t("Sales type"),
                    value: "sales_type",
                    sortable: false
                },
                {
                    text: this.$t("Sales code"),
                    value: "sales_code",
                    sortable: false
                },
                {
                    text: this.$t("VAT included"),
                    value: "vat_included",
                    sortable: false
                },
                {
                    text: this.$t("Currency"),
                    value: "currency_code",
                    sortable: false
                },
                {
                    text: this.$t("prices.Unit price"),
                    value: "unit_price",
                    sortable: false
                },
                {
                    text: this.$t("Minimum quantity"),
                    value: "minimum_quantity",
                    sortable: false
                },
                {
                    text: this.$t("Unit of measure code"),
                    value: "unit_of_measure_code",
                    sortable: false
                },
                {
                    text: this.$t("Starting date"),
                    value: "starting_date",
                    sortable: false
                },
                {
                    text: this.$t("Ending date"),
                    value: "ending_date",
                    sortable: false
                }
            ]
        };
    }
};
</script>
