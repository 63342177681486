import axios from "axios";
import {
    mediavaletApi,
    mediavaletAutClientId,
    mediavaletAuthUrl,
    mediavaletAutPassword,
    mediavaletAutUsername
} from "~/config";
import * as types from "../mutation-types";

/**
 * Initial state
 */
export const state = {
    token: "",
    lastResult: []
};

/**
 * Mutations
 */
export const mutations = {
    [types.LOGOUT](state) {
        state.user = null;
        state.token = null;
        window.localStorage.removeItem("token");
    },

    [types.SET_MEDIAVALET_TOKEN](state, { access_token }) {
        state.token = access_token;
        window.localStorage.setItem("mediavalet_token", access_token);
    }
};

/**
 * Actions
 */
export const actions = {
    async search(actions, payload) {
        if (payload.length < 1) {
            return [];
        }
        let query = "?search=" + payload.join("+") + "&count=25";
        const { data } = await axios.get(mediavaletApi.path("assets") + query, {
            headers: {
                Authorization: "Bearer " + state.token
            }
        });
        state.lastResult = data.payload.assets;
        return state.lastResult;
    },

    async getMVToken({ commit }) {
        const postRaw =
            "grant_type=password&username=" +
            mediavaletAutUsername +
            "&password=" +
            mediavaletAutPassword +
            "&client_id=" +
            mediavaletAutClientId;

        const settings = {
            headers: {
                Accept: "application/json",
                "Content-type": "text/plain",
                "Ocp-Apim-Subscription-Key": "034cd0c5fae443e9a28b4c45b36d369d"
            }
        };

        await axios
            .post(mediavaletAuthUrl + "/token", postRaw, settings)
            .then(({ data }) => {
                commit(types.SET_MEDIAVALET_TOKEN, data);
            });
    },

    destroy({ commit }) {
        commit(types.LOGOUT);
    }
};

/**
 * Getters
 */
export const getters = {
    token: state => state.token,
    lastResult: state => state.lastResult
};
