<template>
    <v-dialog :value="dialog" max-width="500">
        <v-card>
            <v-card-title>
                <v-icon class="mr-2 primary--text">delete_outline</v-icon>
                <h3 class="font-weight-thin">
                    {{ $t("Delete item", { entity: $t(entity) }) }}
                </h3>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
                {{
                    $t("Do you really want to delete this entity", {
                        entity: $t(entity),
                        name: caption
                    })
                }}
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error darken-1" text @click="$emit('close')">
                    {{ $t("Cancel") }}
                </v-btn>
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="success darken-3"
                    text
                    @click="deleteItem"
                    >{{ $t("Delete") }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        dialog: {
            type: Boolean,
            required: true
        },
        caption: {
            type: [String, Object],
            required: true
        },
        entity: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false
        };
    },

    methods: {
        ...mapActions({
            deleteItemPers: "master-data/deleteItem"
        }),

        deleteItem() {
            this.loading = true;
            this.deleteItemPers({ entity: this.entity, item: this.item })
                .then(() => {
                    this.$store.dispatch("ui/showSnackbar", {
                        text: this.$t("Item removed"),
                        color: "success"
                    });
                    this.$emit("close");
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
