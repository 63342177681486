<template>
    <v-card class="elevation-0">
        <v-card-title>
            <h3 class="text-h5 font-weight-thin primary--text pl-4 pt-2 mb-5">
                {{ $t("Declarations") }}
                <productFieldsSync
                    class="ml-2"
                    :readonly="readonly"
                    :fields="[
                        'nutrients',
                        'base_substances',
                        'minor_components'
                    ]"
                    :selected-language="language"
                />
            </h3>
        </v-card-title>
        <v-card-text>
            <v-container py-0 px-2 grid-list-xs fluid>
                <v-layout wrap>
                    <v-flex xs7>
                        <nutrientsTable
                            ref="nutrients"
                            :value="product.nutrients"
                            :language="language"
                            :readonly="readonly"
                            :item-label="$t('Nutrient')"
                            @click="onClick($event)"
                            @input="update('nutrients', $event)"
                        >
                            <template #title>
                                <span class="primary--text text-h6 underline">
                                    {{ $t("Nutrients") }}
                                    <productFieldsSync
                                        small
                                        :fields="['nutrients']"
                                        :readonly="readonly"
                                        :selected-language="language"
                                        with-language-copy
                                        @synced="update('nutrients')"
                                    />
                                </span>
                            </template>
                        </nutrientsTable>
                    </v-flex>
                    <v-flex xs7>
                        <nutrientsTable
                            ref="base_substances"
                            :value="product.base_substances"
                            :language="language"
                            :readonly="readonly"
                            :item-label="$t('Nutrient')"
                            @click="onClick($event)"
                            @input="update('base_substances', $event)"
                        >
                            <template #title>
                                <span class="primary--text text-h6 underline">
                                    {{ $t("Base substances") }}
                                    <productFieldsSync
                                        small
                                        :fields="['base_substances']"
                                        :selected-language="language"
                                        :readonly="readonly"
                                        with-language-copy
                                        @synced="update('base_substances')"
                                    />
                                </span>
                            </template>
                        </nutrientsTable>
                    </v-flex>
                    <v-flex xs7>
                        <nutrientsTable
                            ref="minor_components"
                            :value="product.minor_components"
                            :language="language"
                            :readonly="readonly"
                            :item-label="$t('Minor component')"
                            @click="onClick($event)"
                            @input="update('minor_components', $event)"
                        >
                            <template #title>
                                <span class="primary--text text-h6 underline">
                                    {{ $t("Minor components") }}
                                    <productFieldsSync
                                        small
                                        :fields="['minor_components']"
                                        :selected-language="language"
                                        :readonly="readonly"
                                        with-language-copy
                                        @synced="reload('minor_components')"
                                    />
                                </span>
                            </template>
                        </nutrientsTable>
                    </v-flex>
                    <v-flex xs12>
                        <v-divider></v-divider>
                        <h4 class="mt-5 text-h5 font-weight-thin primary--text">
                            {{ $t("Distribution") }}
                        </h4>
                    </v-flex>
                    <v-flex xs12>
                        <v-select
                            :value="product.distribution_id"
                            :items="attributes.distributions"
                            :item-text="
                                item =>
                                    item.name[language] ||
                                    item.name[fallbackLanguage]
                            "
                            item-value="id"
                            :disabled="readonly"
                            :label="$t('Distribution')"
                            small-chips
                            @input="
                                $emit(
                                    'updateProduct',
                                    $event,
                                    'distribution_id'
                                )
                            "
                        ></v-select>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { api } from "~/config";

export default {
    components: {
        nutrientsTable: require("$comp/shared/HauertNutrientsTable").default,
        productFieldsSync: require("$comp/shared/ProductFieldsSync").default
    },

    props: {
        product: {
            type: Object,
            default: () => {
                return {
                    nutrients: {}
                };
            }
        },
        language: {
            type: String,
            default: "de"
        },
        fallbackLanguage: {
            type: String,
            required: true
        },
        readonly: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            dialog: false,
            copyFrom: "",
            checkbox1: false,
            checkbox2: false,
            search: null,
            items: [],
            searchLoading: false
        };
    },

    computed: {
        ...mapGetters({
            attributes: "master-data/attributes"
        }),

        distributor() {
            return this.product.distributor
                ? this.product.distributor.name
                : "";
        }
    },

    watch: {
        search(val) {
            if (!val || val.length < 3) return;

            this.fetchEntriesDebounced(val);
        }
        /*
    "product.base_substances": {
      handler(newVal) {
        this.$refs.basesubs.refresh(newVal);
      },
      deep: true
    },

    "product.minor_components": {
      handler(newVal) {
        this.$refs.minorcomps.refresh(newVal);
      },
      deep: true
    } */
    },

    methods: {
        ...mapActions({
            setLoading: "ui/setLoading"
        }),

        update(field, value) {
            let payload = this.product[field];
            if (value !== undefined) {
                payload = value;
            }
            this.$emit("updateProduct", payload, field);
        },

        reload(ref) {
            this.$nextTick(() => {
                this.$refs[ref].$forceUpdate();
            });
        },

        synced(ref, field) {
            ref.refresh(this.product[field]);
        },

        fetchEntriesDebounced(val) {
            // cancel pending call
            clearTimeout(this._timerId);

            // delay new call 1000ms
            this._timerId = setTimeout(() => {
                this.items = [];

                this.searchLoading = true;

                axios
                    .get(api.path("products.query", { term: val }))
                    .then(r => r.data)
                    .then(products => {
                        this.items = products.data;
                    })
                    .finally(() => (this.searchLoading = false));
            }, 1000);
        }
    }
};
</script>
