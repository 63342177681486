var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{staticClass:"pl-1",attrs:{"items":_vm.crumbs}},[_c('v-icon',{attrs:{"slot":"divider"},slot:"divider"},[_vm._v("chevron_right")])],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.accounts},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("Usermanagement")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("Create account"))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"editedItem"},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"error-messages":_vm.nameErrors,"label":"Name"},on:{"input":function($event){return _vm.$v.editedItem.name.$touch()},"blur":function($event){return _vm.$v.editedItem.name.$touch()}},model:{value:(
                                                        _vm.editedItem.name
                                                    ),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"\n                                                        editedItem.name\n                                                    "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"error-messages":_vm.emailErrors,"label":"E-Mail"},on:{"input":function($event){return _vm.$v.editedItem.email.$touch()},"blur":function($event){return _vm.$v.editedItem.email.$touch()}},model:{value:(
                                                        _vm.editedItem.email
                                                    ),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"\n                                                        editedItem.email\n                                                    "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"error-messages":_vm.passwordErrors,"label":"Password","type":"password"},on:{"input":function($event){return _vm.$v.editedItem.password.$touch()},"blur":function($event){return _vm.$v.editedItem.password.$touch()}},model:{value:(
                                                        _vm.editedItem.password
                                                    ),callback:function ($$v) {_vm.$set(_vm.editedItem, "password", $$v)},expression:"\n                                                        editedItem.password\n                                                    "}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.roles,"label":"Roles","error-messages":_vm.roleErrors,"persistent-hint":"","single-line":""},on:{"input":function($event){return _vm.$v.editedItem.role.$touch()},"blur":function($event){return _vm.$v.editedItem.role.$touch()}},model:{value:(
                                                        _vm.editedItem.role
                                                    ),callback:function ($$v) {_vm.$set(_vm.editedItem, "role", $$v)},expression:"\n                                                        editedItem.role\n                                                    "}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s("Abbrechen")+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s("Speichern")+" ")])],1)],1)],1)],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("No users created"))+" ")]},proxy:true},{key:"item.actions",fn:function(ref){
                                                    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete-forever ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }