<template>
    <v-flex sm8 md6 lg4>
        <v-card>
            <v-toolbar
                class="elevation-0"
                color="green lighten-5"
                text-xs-center
                height="200"
            >
                <v-layout align-center>
                    <v-flex text-center>
                        <img
                            height="60"
                            src="../../../assets/images/logo_hauert_gruen.svg"
                        />
                        <br />
                        <h3 class="font-weight-light font-italic">
                            m i d d l e w a r e
                        </h3>
                    </v-flex>
                </v-layout>
            </v-toolbar>
            <v-card-text>
                <login-form @success="success"></login-form>
            </v-card-text>
        </v-card>
    </v-flex>
</template>

<script>
export default {
    components: {
        "login-form": require("./LoginForm").default
    },

    methods: {
        success(data) {
            this.$store.dispatch("auth/saveToken", data);
            this.$router.push({ name: "index" });
        }
    }
};
</script>
