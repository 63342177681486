import axios from "axios";
import { api } from "~/config";
import * as types from "../mutation-types";

/**
 * Initial state
 */
export const state = {
    user: null,
    token: window.localStorage.getItem("token"),
    refreshToken: window.localStorage.getItem("refreshToken"),
    tenant: window.localStorage.getItem("tenant"),
    tenants: JSON.parse(window.localStorage.getItem("tenants") || null),
    isSuperAdmin: false
};

/**
 * Mutations
 */
export const mutations = {
    [types.SET_USER](state, { user }) {
        state.user = user;
    },

    [types.SET_TENANT](state, tenant) {
        state.tenant = tenant;
        window.localStorage.setItem("tenant", tenant);
    },

    [types.LOGOUT](state) {
        state.user = null;
        state.token = null;
        window.localStorage.removeItem("token");
    },

    [types.FETCH_USER_FAILURE](state) {
        state.user = null;
        window.localStorage.removeItem("token");
    },

    [types.SET_TOKEN](state, { access_token, refresh_token, tenant, tenants }) {
        state.token = access_token;
        state.refreshToken = refresh_token;
        state.tenant = tenant;
        state.tenants = tenants;
        window.localStorage.setItem("token", access_token);
        window.localStorage.setItem("refreshToken", refresh_token);
        window.localStorage.setItem("tenant", tenant);
        window.localStorage.setItem("tenants", JSON.stringify(tenants));
    }
};

/**
 * Actions
 */
export const actions = {
    saveToken({ commit }, payload) {
        commit(types.SET_TOKEN, payload);
    },

    async fetchUser({ commit }) {
        try {
            const { data } = await axios.get(api.path("me"));
            commit(types.SET_USER, data.data);
        } catch (e) {
            commit(types.FETCH_USER_FAILURE);
        }
    },

    setUser({ commit }, payload) {
        commit(types.SET_USER, payload);
    },

    async logout({ commit }) {
        await axios.post(api.path("logout"));
        commit(types.LOGOUT);
    },

    destroy({ commit }) {
        commit(types.LOGOUT);
    },

    setTenant({ commit }, tenant) {
        axios.put(api.path("users.settings"), { last_tenant: tenant });
        commit(types.SET_TENANT, tenant);
    }
};

/**
 * Getters
 */
export const getters = {
    user: state => state.user,
    check: state => state.user !== null,
    token: state => state.token,
    tenant: state => state.tenant,
    tenants: state => state.tenants,
    isSuperAdmin: state => state.isSuperAdmin,
    permissions: state => (state.user !== null ? state.user.permissions : [])
};
