<template>
    <v-navigation-drawer
        color="primary darken-4"
        class="mt-0"
        dark
        app
        :clipped="true"
        permanent
        :mini-variant="navigationDrawerMini"
    >
        <v-list class="pa-0" dense>
            <div
                v-for="(group, index) in items"
                :key="index"
                class="py-0"
                dense
            >
                <v-divider
                    v-if="!!group.length"
                    class="mb-2"
                    :class="{ 'mt-0': !index, 'mt-2': !!index }"
                ></v-divider>
                <div v-for="item in group" :key="item.title">
                    <v-list-group
                        v-if="!!item.items"
                        v-model="item.active"
                        :prepend-icon="item.icon"
                        no-action
                    >
                        <template #activator>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ $t(item.title) }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item
                            v-for="subItem in item.items"
                            :key="subItem.title"
                            active-class="highlighted"
                            :to="subItem.to"
                            ripple
                            :exact="
                                subItem.exact !== undefined
                                    ? subItem.exact
                                    : true
                            "
                            @click="subItem.action ? subItem.action() : false"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ $t(subItem.title) }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon>{{ subItem.icon }}</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item
                        v-else
                        :to="item.to"
                        ripple
                        :exact="item.exact !== undefined ? item.exact : true"
                        @click="item.action ? item.action() : false"
                    >
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ $t(item.title) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data: () => ({
        items: [],
        name: null
    }),

    computed: {
        ...mapGetters({
            auth: "auth/user",
            navigationDrawerMini: "ui/navigationDrawerMini",
            permissions: "auth/permissions",
            tenant: "auth/tenant"
        })
    },

    watch: {
        authName(val) {
            if (val) {
                this.name = val;
            }
        }
    },

    mounted() {
        this.name = this.auth.name;
        this.navigation();
    },

    methods: {
        navToggle() {
            this.$store.dispatch("ui/toggleNavigationDrawerMini");
        },

        async logout() {
            await this.$store.dispatch("auth/logout");

            this.$store.dispatch("ui/showSnackbar", {
                text: this.$t("You are logged out."),
                color: "info"
            });
            this.$router.push({ name: "login" });
        },

        navigationFullWidth() {
            //this.$store.dispatch("ui/setNavigationDrawerFull");
        },

        navigation() {
            this.items = [
                [
                    {
                        title: "Dashboard",
                        icon: "mdi-view-dashboard",
                        to: { name: "index" },
                        exact: true
                    }
                ]
            ];

            const products = [];

            if (this.permissions[this.tenant].includes("search products")) {
                products.push({
                    title: "Liste",
                    icon: "mdi-layers-search-outline",
                    to: { name: "products" },
                    exact: true
                });
            }

            if (this.permissions[this.tenant].includes("export products")) {
                products.push({
                    title: "Export",
                    icon: "mdi-database-export",
                    to: { name: "products-export" },
                    exact: true
                });
            }

            if (this.permissions[this.tenant].includes("sync products")) {
                products.push({
                    title: "Sync",
                    icon: "mdi-sync-circle",
                    to: { name: "products-sync" },
                    exact: true
                });
            }

            if (this.permissions[this.tenant].includes("edit products")) {
                products.push({
                    title: "Serienupdate",
                    icon: "mdi-pencil-box-multiple-outline",
                    to: { name: "products-bulk-update" },
                    exact: true
                });
            }

            if (products.length > 0) {
                this.items.push([
                    {
                        title: "Products",
                        icon: "mdi-dolly",
                        to: { name: "products" },
                        exact: false,
                        items: products
                    }
                ]);
            }

            if (this.permissions[this.tenant].includes("search masterdata")) {
                this.items.push([
                    {
                        title: "Master data",
                        icon: "mdi-database",
                        to: { name: "masterData" },
                        exact: false
                    }
                ]);
            }

            this.items.push([
                {
                    title: "Profile",
                    icon: "person",
                    to: {
                        name: "profile"
                    },
                    exact: false
                }
            ]);

            if (this.permissions[this.tenant].includes("edit users")) {
                this.items.push([
                    {
                        title: "Zugänge",
                        icon: "mdi-account-multiple",
                        to: {
                            name: "accounts"
                        },
                        exact: false
                    }
                ]);
            }

            this.items.push([
                {
                    title: "Logout",
                    icon: "power_settings_new",
                    action: this.logout
                }
            ]);
        }
    }
};
</script>

<style scoped>
.highlighted {
    color: #ffea00;
}
</style>
